<script context="module">
  import {
    CardLayout,
    CardLayoutMixed,
    CardLayoutMasonry,
    Pagination,
    AvatarCard,
    LinkCard,
    StoryCard,
    CenteredLayout
  } from "@ObamaFoundation/of-design-system";
  import HeadlineCard from "$lib/components/Card/Vertical/HeadlineCard.svelte";
  import { ITEM_PREVIEW_LIST_FORMS } from "$lib/utilities/constants";

  const GRID_LAYOUTS = {
    TwoColumns: "twoColumns",
    ThreeColumns: "threeColumns",
    FourColumns: "fourColumns",
    SixColumns: "sixColumns"
  };

  const FEATURED_LAYOUTS = {
    DoubleFeatured: "doubleFeatured",
    SingleFullWidthFeature: "singleFullWidthFeature",
    SingleTwoThirdsFeature: "singleTwoThirdsFeature"
  };

  const ITEM_COMPONENTS_BY_ITEMS_FORM_FACTOR = {
    [ITEM_PREVIEW_LIST_FORMS.Headline]: HeadlineCard,
    [ITEM_PREVIEW_LIST_FORMS.Story2]: StoryCard,
    [ITEM_PREVIEW_LIST_FORMS.Story3]: StoryCard,
    [ITEM_PREVIEW_LIST_FORMS.Story3FeatureImageLeft]: StoryCard,
    [ITEM_PREVIEW_LIST_FORMS.Story3FeatureImageRight]: StoryCard,
    [ITEM_PREVIEW_LIST_FORMS.Story3FeatureTwoThirds]: StoryCard,
    [ITEM_PREVIEW_LIST_FORMS.Story3FeatureHalf]: StoryCard,
    [ITEM_PREVIEW_LIST_FORMS.Link2]: LinkCard,
    [ITEM_PREVIEW_LIST_FORMS.Link2Feature]: LinkCard,
    [ITEM_PREVIEW_LIST_FORMS.Link3]: LinkCard,
    [ITEM_PREVIEW_LIST_FORMS.Link4]: LinkCard,
    [ITEM_PREVIEW_LIST_FORMS.Link4TitleOnly]: LinkCard,
    [ITEM_PREVIEW_LIST_FORMS.Avatar4]: AvatarCard,
    [ITEM_PREVIEW_LIST_FORMS.Avatar6]: AvatarCard
  };

  const CARD_LAYOUT_COMPONENTS_BY_ITEMS_FORM_FACTOR = {
    [ITEM_PREVIEW_LIST_FORMS.Headline]: null,
    [ITEM_PREVIEW_LIST_FORMS.Story2]: CardLayoutMasonry,
    [ITEM_PREVIEW_LIST_FORMS.Story3]: CardLayoutMasonry,
    [ITEM_PREVIEW_LIST_FORMS.Story3FeatureImageLeft]: CardLayoutMixed,
    [ITEM_PREVIEW_LIST_FORMS.Story3FeatureImageRight]: CardLayoutMixed,
    [ITEM_PREVIEW_LIST_FORMS.Story3FeatureTwoThirds]: CardLayoutMixed,
    [ITEM_PREVIEW_LIST_FORMS.Story3FeatureHalf]: CardLayoutMixed,
    [ITEM_PREVIEW_LIST_FORMS.Link2]: CardLayout,
    [ITEM_PREVIEW_LIST_FORMS.Link2Feature]: CardLayoutMixed,
    [ITEM_PREVIEW_LIST_FORMS.Link3]: CardLayout,
    [ITEM_PREVIEW_LIST_FORMS.Link4]: CardLayout,
    [ITEM_PREVIEW_LIST_FORMS.Link4TitleOnly]: CardLayout,
    [ITEM_PREVIEW_LIST_FORMS.Avatar4]: CardLayout,
    [ITEM_PREVIEW_LIST_FORMS.Avatar6]: CardLayout
  };

  export const LAYOUT_BY_ITEMS_FORM_FACTOR = {
    [ITEM_PREVIEW_LIST_FORMS.Headline]: null,
    [ITEM_PREVIEW_LIST_FORMS.Story2]: GRID_LAYOUTS.TwoColumns,
    [ITEM_PREVIEW_LIST_FORMS.Story3]: GRID_LAYOUTS.ThreeColumns,
    [ITEM_PREVIEW_LIST_FORMS.Story3FeatureImageLeft]: GRID_LAYOUTS.ThreeColumns,
    [ITEM_PREVIEW_LIST_FORMS.Story3FeatureImageRight]: GRID_LAYOUTS.ThreeColumns,
    [ITEM_PREVIEW_LIST_FORMS.Story3FeatureTwoThirds]: GRID_LAYOUTS.ThreeColumns,
    [ITEM_PREVIEW_LIST_FORMS.Story3FeatureHalf]: GRID_LAYOUTS.ThreeColumns,
    [ITEM_PREVIEW_LIST_FORMS.Link2]: GRID_LAYOUTS.TwoColumns,
    [ITEM_PREVIEW_LIST_FORMS.Link2Feature]: GRID_LAYOUTS.TwoColumns,
    [ITEM_PREVIEW_LIST_FORMS.Link3]: GRID_LAYOUTS.ThreeColumns,
    [ITEM_PREVIEW_LIST_FORMS.Link4]: GRID_LAYOUTS.FourColumns,
    [ITEM_PREVIEW_LIST_FORMS.Link4TitleOnly]: GRID_LAYOUTS.FourColumns,
    [ITEM_PREVIEW_LIST_FORMS.Avatar4]: GRID_LAYOUTS.FourColumns,
    [ITEM_PREVIEW_LIST_FORMS.Avatar6]: GRID_LAYOUTS.SixColumns
  };

  const FEATURED_LAYOUTS_BY_ITEMS_FORM_FACTOR = {
    [ITEM_PREVIEW_LIST_FORMS.Headline]: null,
    [ITEM_PREVIEW_LIST_FORMS.Story2]: null,
    [ITEM_PREVIEW_LIST_FORMS.Story3]: null,
    [ITEM_PREVIEW_LIST_FORMS.Story3FeatureImageLeft]: FEATURED_LAYOUTS.SingleFullWidthFeature,
    [ITEM_PREVIEW_LIST_FORMS.Story3FeatureImageRight]: FEATURED_LAYOUTS.SingleFullWidthFeature,
    [ITEM_PREVIEW_LIST_FORMS.Story3FeatureTwoThirds]: FEATURED_LAYOUTS.SingleTwoThirdsFeature,
    [ITEM_PREVIEW_LIST_FORMS.Story3FeatureHalf]: FEATURED_LAYOUTS.ThreeColumns,
    [ITEM_PREVIEW_LIST_FORMS.Link2]: null,
    [ITEM_PREVIEW_LIST_FORMS.Link2Feature]: FEATURED_LAYOUTS.DoubleFeatured,
    [ITEM_PREVIEW_LIST_FORMS.Link3]: null,
    [ITEM_PREVIEW_LIST_FORMS.Link4]: null,
    [ITEM_PREVIEW_LIST_FORMS.Link4TitleOnly]: null,
    [ITEM_PREVIEW_LIST_FORMS.Avatar4]: null,
    [ITEM_PREVIEW_LIST_FORMS.Avatar6]: null
  };

  export const SPLIT_LAYOUT_VARIANTS = /** @type { const } */ {
    LAYOUT_50_50: "50/50",
    LAYOUT_25_75: "25/75",
    LAYOUT_75_25: "75/25"
  };

  const GRID_IMAGE_SIZES = {
    [GRID_LAYOUTS.TwoColumns]: { sm: "100vw", md: "50vw" },
    [GRID_LAYOUTS.ThreeColumns]: { sm: "100vw", md: "50vw", lg: "33vw" },
    [GRID_LAYOUTS.FourColumns]: { sm: "50vw", lg: "25vw" },
    [GRID_LAYOUTS.SixColumns]: { sm: "50vw", md: "25vw", lg: "16vw" }
  };

  const FEATURED_IMAGE_SIZES = {
    [ITEM_PREVIEW_LIST_FORMS.Story3FeatureImageLeft]: { sm: "100vw", lg: "40vw" },
    [ITEM_PREVIEW_LIST_FORMS.Story3FeatureImageRight]: { sm: "100vw", lg: "40vw" },
    [ITEM_PREVIEW_LIST_FORMS.Story3FeatureTwoThirds]: { sm: "100vw", md: "50vw", lg: "66vw" },
    [ITEM_PREVIEW_LIST_FORMS.Story3FeatureHalf]: { sm: "100vw", md: "50vw" },
    [ITEM_PREVIEW_LIST_FORMS.Link2Feature]: { sm: "100vw", md: "50vw" }
  };

  const getItemsWithImageSizes = (items, formFactor, layout) =>
    items.map((item, index) => {
      let widthsAt = GRID_IMAGE_SIZES[layout];
      if (formFactor === ITEM_PREVIEW_LIST_FORMS.Link2Feature && (index === 0 || index === 1)) {
        widthsAt = FEATURED_IMAGE_SIZES[formFactor];
      } else if (FEATURED_IMAGE_SIZES[formFactor] && index === 0) {
        widthsAt = FEATURED_IMAGE_SIZES[formFactor];
      }
      const sizes = sizesForBreakpoints(widthsAt);

      return {
        ...item,
        featuredImage: {
          ...item.featuredImage,
          sizes
        }
      };
    });
</script>

<script>
  // @ts-check
  import { beforeUpdate, getContext, tick } from "svelte";
  // eslint-disable-next-line  @ObamaFoundation/of-svelte/no-stores
  import { page } from "$app/stores";
  // eslint-disable-next-line  @ObamaFoundation/of-svelte/no-browser
  import { browser } from "$app/environment";
  import { sizesForBreakpoints } from "$lib/utilities";

  export let iplId;

  /** @type { string } */
  let className = "";
  export { className as class };

  export let itemsFormFactor;

  export let items = [];

  $: numberOfPages = items.length;

  let activeItems = [];
  let activePage = 1;
  let containerRef;

  $: currentUrl = $page?.url;

  const Constants = /** @type {const} */ {
    offsetSpacingToAccountForHeader: 300
  };

  const splitLayoutVariant = getContext("splitLayoutVariant");

  const setActivePage = () => {
    // get data from session history (when paginating) or search params (when navigating)
    const ipl = history.state?.ipl || currentUrl?.searchParams.get("ipl");

    if (ipl && ipl === iplId) {
      const page = history.state?.page || parseInt(currentUrl?.searchParams.get("page"));
      activePage = !isNaN(page) ? page : 1;
    } else {
      activePage = 1;
    }
  };

  beforeUpdate(async () => {
    // wait for all state updates to complete
    await tick();
    setActivePage();
  });

  if (browser) {
    // going back and forth in history doesn't actually trigger a page load in our case;
    // use callback instead
    window.onpopstate = setActivePage;
  }

  async function onNavigate(event) {
    const newUrl = new URL(currentUrl.href);
    const page = event.detail.page;

    if (!history.state?.page && !currentUrl?.searchParams.get("page")) {
      // rewrite first page session history
      newUrl.searchParams.set("ipl", iplId);
      newUrl.searchParams.set("page", "1");
      history.replaceState(
        {
          ipl: iplId,
          page: 1
        },
        "",
        newUrl
      );
    }

    newUrl.searchParams.set("ipl", iplId);
    newUrl.searchParams.set("page", page);
    history.pushState(
      {
        ipl: iplId,
        page
      },
      "",
      newUrl
    );
    setActivePage();

    setTimeout(() => {
      window.scrollTo({
        top: containerRef?.offsetTop - Constants.offsetSpacingToAccountForHeader || 0,
        behavior: "smooth"
      });
    }, 10);
  }

  /**
   * Determines which component to use to render each card
   */
  const itemComponent = ITEM_COMPONENTS_BY_ITEMS_FORM_FACTOR[itemsFormFactor];
  const cardLayoutComponent = CARD_LAYOUT_COMPONENTS_BY_ITEMS_FORM_FACTOR[itemsFormFactor];
  const layout = LAYOUT_BY_ITEMS_FORM_FACTOR[itemsFormFactor];
  const featuredLayout = FEATURED_LAYOUTS_BY_ITEMS_FORM_FACTOR[itemsFormFactor];

  /* Handle use cases where we show two columns on the small breakpoint */
  const mobileColumnCount =
    itemsFormFactor === ITEM_PREVIEW_LIST_FORMS.Avatar4 ||
    itemsFormFactor === ITEM_PREVIEW_LIST_FORMS.Avatar6
      ? 2
      : 1;

  const isMasonry = [
    ITEM_PREVIEW_LIST_FORMS.Story2,
    ITEM_PREVIEW_LIST_FORMS.Story3,
    ITEM_PREVIEW_LIST_FORMS.Story3FeatureImageLeft,
    ITEM_PREVIEW_LIST_FORMS.Story3FeatureImageRight,
    ITEM_PREVIEW_LIST_FORMS.Story3FeatureTwoThirds,
    ITEM_PREVIEW_LIST_FORMS.Story3FeatureHalf
  ].includes(itemsFormFactor);

  $: {
    activeItems = items?.[activePage - 1] ?? [];
    activeItems = getItemsWithImageSizes(activeItems, itemsFormFactor, layout);
  }
</script>

<div class="{className} ipl" use:setActivePage bind:this={containerRef}>
  {#if items.length}
    <div>
      {#if itemsFormFactor === ITEM_PREVIEW_LIST_FORMS.Headline}
        <CenteredLayout class="pt-10">
          {#each activeItems as item}
            <HeadlineCard {...item} />
          {/each}
        </CenteredLayout>
      {:else}
        <svelte:component
          this={cardLayoutComponent}
          variant={isMasonry ? "masonry" : "default"}
          {layout}
          {splitLayoutVariant}
          {featuredLayout}
          component={itemComponent}
          items={activeItems}
          {mobileColumnCount}
        />
      {/if}
    </div>
  {:else}
    <div class="px-7.5 lg:px-15">
      <h2 class="heading-xs mt-7.5 md:mt-15">No Results</h2>
      <p class="body-sm mt-[12px] md:mt-4">Try using different filters</p>
    </div>
  {/if}
  <Pagination
    totalPages={numberOfPages}
    initialActivePage={activePage}
    maxVisiblePagesDesktop={10}
    maxVisiblePagesMobile={4}
    class="mt-7.5"
    on:navigate={onNavigate}
  />
</div>
