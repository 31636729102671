<script>
  import Video from "$lib/components/Video.svelte";
  import { getVideoComponentPropsForVideoContentType } from "$lib/utilities/contentTypes/video";

  export let data;
  /** @type { string } */
  let className = "";
  export { className as class };

  const componentProps = getVideoComponentPropsForVideoContentType(data);
</script>

<Video {...componentProps} class={className} />
