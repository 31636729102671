<script context="module">
  import BlockQuote from "$lib/contentTypes/BlockQuote.svelte";
  import Embed from "$lib/contentTypes/Embed.svelte";
  import ImageComponent from "$lib/contentTypes/ImageComponent.svelte";
  import LinkComponent from "$lib/contentTypes/LinkComponent.svelte";
  import HTMLSnippet from "$lib/contentTypes/HTMLSnippet.svelte";
  import Heading from "./Heading.svelte";

  export const BASE_COMPONENT_MAP = {
    blockQuote: BlockQuote,
    imageComponent: ImageComponent,
    embed: Embed,
    linkComponent: LinkComponent,
    htmlSnippet: HTMLSnippet,
    /** @deprecated */
    fundraiseUpElement: HTMLSnippet
  };

  const { BLOCKS, INLINES } = types;

  // ESCAPE HATCH.
  // REMOVE THIS WHEN HEADINGS ARE NO LONGER LINKS IN CONTENTFUL
  // AND VALIDATIONS ARE IN PLACE
  // --- FROM HERE --
  const hyperlinkToText = (contentArray) => {
    const transformed = contentArray.map((a) => {
      if (a.nodeType == "hyperlink") {
        // return the text content of the hyperlink
        return a.content[0];
      }
      return a;
    });
    // combine the values to make it one string
    const string = transformed.reduce((acc, c) => {
      return (acc += c.value);
    }, "");

    return string;
  };

  const getHeadingText = (n) => {
    if (n.content.length > 1) {
      return hyperlinkToText(n.content);
    } else if (n.content[0]) {
      return n.content[0].value;
    }
  };

  export const createHeading = (el, props) => {
    const text = getHeadingText(el);
    // explicitly skip headings with no content
    if (!text || text.match(/^\s*$/)) {
      // return an iterable value, but undefined.
      return [undefined, undefined];
    }
    return [Heading, { ...props, text }];
  };
  // --- TO HERE

  export const getComponentClassNames = (typename) => {
    const COMPONENT_SPACING = "embedded-content";
    const BODY_COPY_SPACING = "rich-text";
    const CONTENT_TYPE_EXCEPTION_LIST = ["fundraiseUpElement", "htmlSnippet"];
    return CONTENT_TYPE_EXCEPTION_LIST.includes(typename) ? BODY_COPY_SPACING : COMPONENT_SPACING;
  };
</script>

<script>
  import * as types from "@contentful/rich-text-types";
  import Element from "$lib/components/Element.svelte";
  import Link from "$lib/components/Link.svelte";
  import { error } from "@sveltejs/kit";
  import { Rule } from "@ObamaFoundation/of-design-system";
  import { removeQuotationMarks } from "$lib/utilities/components/richText";
  import BlockQuoteComponent from "$lib/components/BlockQuote.svelte";
  import SmartLayout from "$lib/components/SmartLayout.svelte";
  import { setContext, getContext } from "svelte";

  const { helpers } = types;

  let className = "";
  export { className as class };
  export let node;
  export let textId;
  export let componentMap = BASE_COMPONENT_MAP;

  if (!node) {
    throw error(500, "<RichText /> requires a `node` parameter.");
  }

  const isSvelteComponent = (nodeType) => {
    return [
      BLOCKS.EMBEDDED_ENTRY,
      INLINES.EMBEDDED_ENTRY,
      INLINES.HYPERLINK,
      INLINES.ASSET_HYPERLINK,
      BLOCKS.HEADING_1,
      BLOCKS.HEADING_2,
      BLOCKS.HEADING_3,
      BLOCKS.HEADING_4,
      BLOCKS.HEADING_5,
      BLOCKS.HR,
      BLOCKS.QUOTE
    ].includes(nodeType);
  };

  export const hasText = (el) => {
    let foundText = false;
    el.content.forEach((content) => {
      // explicitly skip paragraphs with no content, but sometimes they may also just be a hyperlink
      //or maybe something else too?
      if (content.nodeType !== "text" || (content.value && !content.value.match(/^\s*$/))) {
        foundText = true;
      }
    });

    return foundText;
  };

  const createComponent = (typename, data, validComponents) => {
    if (typename === "freeRichTextComponent") {
      return ["self", data];
    }
    let component = validComponents[typename];

    if (!component) {
      // eslint-disable-next-line no-console
      console.warn(
        `Component not found in RichText component map: ${typename}. Allowed components include ${Object.keys(
          validComponents
        ).join(", ")}`
      );
    }
    const props = component ? { data } : undefined;
    return [component, { ...props, class: getComponentClassNames(typename) }];
  };

  const createListItem = (el, props) => {
    // explicitly skip list items with no content
    if (!hasText(el)) {
      // return an iterable value, but undefined.
      return [undefined, undefined];
    }

    return ["li", props];
  };

  const getClassesFromMarks = (marks) => {
    let textCss = "";
    marks.forEach((mark) => {
      switch (mark.type) {
        case "bold":
          textCss += " font-bold";
          return;
        case "italic":
          textCss += " italic";
          return;
        case "underline":
          textCss += "underline";
          return;
      }
    });
    return textCss;
  };

  const createParagraph = (el, props) => {
    if (!hasText(el)) {
      // return an iterable value, but undefined.
      return [undefined, undefined];
    }

    return ["p", props];
  };

  const createRenderable = (n) => {
    const data = n.data.target;
    const typename = n.data.target?.sys?.contentType?.sys.id;

    const MAP = {
      [BLOCKS.DOCUMENT]: () => ["div", { class: "embedded-content" }],
      [BLOCKS.PARAGRAPH]: (el) =>
        createParagraph(el, { class: "rich-text rich-text-paragraph body-md" }),
      [BLOCKS.HEADING_1]: (el) =>
        createHeading(el, { class: "rich-text heading-xl", suggestedHeadingLevel: 1 }),
      [BLOCKS.HEADING_2]: (el) =>
        createHeading(el, { class: "rich-text heading-lg", suggestedHeadingLevel: 2 }),
      [BLOCKS.HEADING_3]: (el) =>
        createHeading(el, { class: "rich-text heading-md", suggestedHeadingLevel: 3 }),
      [BLOCKS.HEADING_4]: (el) =>
        createHeading(el, { class: "rich-text heading-sm", suggestedHeadingLevel: 4 }),
      [BLOCKS.HEADING_5]: (el) =>
        createHeading(el, { class: "rich-text heading-xs", suggestedHeadingLevel: 5 }),
      [BLOCKS.HEADING_6]: (el) =>
        createParagraph(el, { class: "rich-text body-lg", suggestedHeadingLevel: 6 }),
      [BLOCKS.EMBEDDED_ENTRY]: () => createComponent(typename, data, componentMap),
      [BLOCKS.UL_LIST]: () => ["ul", { class: "rich-text rich-text-ul", role: "list" }],
      [BLOCKS.OL_LIST]: () => ["ol", { class: "rich-text rich-text-ol", role: "list" }],
      [BLOCKS.LIST_ITEM]: (el) => createListItem(el, { class: "rich-text rich-text-li" }),
      [BLOCKS.QUOTE]: (el) => [
        BlockQuoteComponent,
        {
          class: "embedded-content",
          quoteContent: removeQuotationMarks(el.content[0]?.content[0]?.value)
        }
      ],
      [BLOCKS.HR]: () => [Rule, { class: "rich-text rich-text-hr" }],
      [INLINES.ASSET_HYPERLINK]: (el) => [
        Link,
        {
          label: hyperlinkToText(el.content),
          url: el.data?.target?.fields?.file?.url,
          variant: "inline"
        }
      ],
      [INLINES.ENTRY_HYPERLINK]: () => ["img", {}],
      [INLINES.EMBEDDED_ENTRY]: () => createComponent(typename, data, componentMap),
      [INLINES.HYPERLINK]: (el) => [
        Link,
        {
          label: hyperlinkToText(el.content),
          url: el.data.uri,
          variant: "inline"
        }
      ]
    };

    return MAP[n.nodeType](n);
  };

  const [elementOrComponent, props] = createRenderable(node);
  const IS_NOT_SKIPPED = elementOrComponent !== undefined;
  const IS_SVELTE_COMPONENT = isSvelteComponent(node.nodeType);
  const componentClass = props?.class ?? "";
  const previousNodeType = getContext("nodeContext");

  setContext("nodeContext", { previousNodeType, nodeType: node.nodeType });
</script>

{#if IS_SVELTE_COMPONENT && IS_NOT_SKIPPED}
  {#if elementOrComponent === "self"}
    <SmartLayout class="{componentClass} {className}">
      <!-- Element is a FreeRichTextComponent -->
      <svelte:self
        node={props.fields?.richTextContent}
        {textId}
        {componentMap}
        class="{componentClass} {className}"
      />
    </SmartLayout>
  {:else}
    <SmartLayout class="{componentClass} {className}">
      <svelte:component this={elementOrComponent} {...props} class="{componentClass} {className}" />
    </SmartLayout>
  {/if}
{:else if IS_NOT_SKIPPED}
  <SmartLayout class="{componentClass} {className}">
    <Element {textId} type={elementOrComponent} {...props} class={componentClass}>
      {#each node.content as component}
        {#if helpers.isText(component)}
          {#if component.marks && component.marks.length > 0}
            <span class={getClassesFromMarks(component.marks)}>{component.value}</span>
          {:else}
            {component.value}
          {/if}
        {:else}
          <svelte:self node={component} {textId} class={className} {componentMap} />
        {/if}
      {/each}
    </Element>
  </SmartLayout>
{/if}
