/**
 * extract the productRoot from a url path
 * @param {string | URL} url
 * @returns {string} productRootUrl
 */
export function parseProductRootUrl(url) {
  if (!url) {
    return "/";
  }

  if (typeof url === "string") {
    return extractProductRootUrl(url);
  } else if (url?.pathname) {
    return extractProductRootUrl(url.pathname);
  } else {
    // This return value has the effect of defaulting to the obama.org product
    return "/";
  }
}

function normalizeUrl(path) {
  if (path === "") {
    return "/";
  }
  if (path?.startsWith("/") && path?.endsWith("/")) {
    return path;
  }
  if (path?.startsWith("/")) {
    return `${path}/`;
  }
  if (path?.endsWith("/")) {
    return `/${path}`;
  }

  return `/${path}/`;
}

function extractProductRootUrl(path, defaultRootUrl = "/") {
  if (path === "/") {
    return defaultRootUrl;
  }
  // We normalize the path to ensure we're dealing with leading and trailing slashes.
  const url = normalizeUrl(path);
  const [, productRoot] = url.split("/");
  // If the productRoot is empty, we'll return an empty string.
  // This has the effect of selecting the obama.org product.
  return normalizeUrl(productRoot) || defaultRootUrl;
}

/**
 * use menu trigger (same Link Component entry used in utility menu) to find matching primary or secondary (footer) menu for given product
 *
 * @param {Array<Object>} productMenus
 * @param {string} productRoot
 * @returns {Object} menu
 */
export function findMenuForProduct(productMenus, productRoot) {
  try {
    const menus = productMenus.filter((item) => item.contentType === "menu");
    if (!menus.length) {
      throw new Error("no menus found!");
    }

    const findFn = (productRoot) => (menu) => {
      const pathMatch = new RegExp(`^/?${productRoot}/?$`);
      return menu.fields.trigger?.fields.destination?.fields.url?.match(pathMatch);
    };

    return menus.find(findFn(productRoot)) || menus.find(findFn("/"));
  } catch (e) {
    // Previous iteration of this wanted us to swallow this error, so we're keeping that behavior.
    // eslint-disable-next-line no-console
    console.warn(e);
  }
}
