<script>
  import { getContext } from "svelte";
  import { GridContainer, Video } from "@ObamaFoundation/of-design-system";

  /** @type { string } */
  let className = "";
  export { className as class };
  export let header;
  export let body;
  export let videoUrl;
  export let videoId = undefined;
  export let caption = undefined;
  export let videoCoverImage = undefined;
  export let loopSection = undefined;

  const isContainerized = !!getContext("containerized");
</script>

{#if isContainerized}
  <Video
    {header}
    {body}
    {videoCoverImage}
    {videoUrl}
    {videoId}
    {caption}
    {loopSection}
    class={className}
  />
{:else}
  <GridContainer containerClass={className}>
    <Video
      {header}
      {body}
      {videoCoverImage}
      {videoUrl}
      {videoId}
      {caption}
      {loopSection}
      class={className}
    />
  </GridContainer>
{/if}
