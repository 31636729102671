<script>
  import { onMount } from "svelte";

  export let data;

  let fbClass = data.fields.url.indexOf("watch") > 0 ? "fb-video" : "fb-post";

  onMount(() => {
    window.FB && window.FB.XFBML.parse();
  });
</script>

<svelte:head>
  <script
    id="facebook-of"
    async={true}
    defer={false}
    crossorigin="anonymous"
    src="https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v19.0"
    nonce="D1ZbbVhD"
  ></script>
</svelte:head>

<div class="w-full overflow-x-auto mx-auto bg-white" data-chromatic="ignore">
  <div class="min-w-[350px] md:flex md:justify-center">
    <div id="fb-root" />
    <div class="facebook-of">
      <div class={fbClass} data-href={data.fields.url} data-show-text="true" data-lazy="true" />
    </div>
  </div>
</div>
