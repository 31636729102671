<script>
  // eslint-disable-next-line  @ObamaFoundation/of-svelte/no-stores
  import { navigating } from "$app/stores";
  import Static from "$lib/components/MenuTypes/Static/Static.svelte";
  import TertiaryMenu from "$lib/components/TertiaryMenu.svelte";

  export const MENU_TYPE_MAP = {
    "Static Menu": Static,
    "Tertiary Menu": TertiaryMenu
  };

  export let data;

  export let isOpen = false;

  function toggle(e) {
    e.preventDefault();
    isOpen = !isOpen;
  }
  function close() {
    isOpen = false;
  }
  $: $navigating && close();
  let menuContainer = MENU_TYPE_MAP[data.fields.type];

  /** @type { string } */
  let className = "";
  export { className as class };
</script>

<svelte:component this={menuContainer} {data} on:click={toggle} {isOpen} class={className} />
