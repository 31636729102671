<script>
  import { getContext } from "svelte";
  import { CallToAction, GridContainer } from "@ObamaFoundation/of-design-system";

  /** @type { string } */
  let className = "";
  export { className as class };

  export let title;
  export let theme;
  export let featuredImage;
  export let description;
  export let cta;

  const isContainerized = !!getContext("containerized");
</script>

{#if isContainerized}
  <CallToAction
    class={className}
    {title}
    id={cta?.id}
    {theme}
    {featuredImage}
    {description}
    {cta}
  />
{:else}
  <GridContainer containerClass={className}>
    <CallToAction {title} id={cta?.id} {theme} {featuredImage} {description} {cta} />
  </GridContainer>
{/if}
