<script>
  import { setContext } from "svelte";
  import Section from "$lib/components/Section.svelte";
  import SplitLayoutComponent from "$lib/contentTypes/SplitLayoutComponent.svelte";
  import SmartLayout from "$lib/components/SmartLayout.svelte";
  import HeroComponent from "./HeroComponent.svelte";

  export let data;
  /** @type { string } */
  let className = "";
  export { className as class };

  setContext("sectionDescendant", true);
</script>

<HeroComponent
  data={{
    fields: {
      formFactor: "Detail",
      title: data.fields.name,
      secondaryText: data.fields.subHeadline
    }
  }}
/>

<Section class={className}>
  <SmartLayout>
    <SplitLayoutComponent
      data={{
        fields: {
          formFactor: "50/50",
          itemsLeft: data.fields.profileImage ? [data.fields.profileImage] : [],
          itemsRight: data.fields.bio
            ? [
                {
                  sys: {
                    contentType: {
                      sys: {
                        id: "freeRichTextComponent"
                      }
                    }
                  },
                  fields: {
                    richTextContent: data.fields.bio
                  }
                }
              ]
            : []
        }
      }}
    />
  </SmartLayout>
</Section>
