<script>
  import { getContext } from "svelte";

  import { GridContainer } from "@ObamaFoundation/of-design-system";

  let className = null;
  export { className as class };

  let isContainerized = getContext("containerized");
</script>

{#if isContainerized}
  <div class={className}>
    <slot />
  </div>
{:else}
  <GridContainer containerClass={className}>
    <slot />
  </GridContainer>
{/if}
