<script>
  // @ts-check
  // eslint-disable-next-line  @ObamaFoundation/of-svelte/no-stores
  import { page } from "$app/stores";

  import EmailSignupForm from "$lib/components/EmailSignupForm.svelte";
  import { SALESFORCE_CONSTANTS } from "$lib/utilities/constants";
  import { getFullUrl } from "$lib/utilities/url-helpers";
  import { getContext } from "svelte";

  /** @type { string } */
  let className = "";
  export { className as class };

  /** The type of layout under the featured layout
   * @type { "vertical" | "horizontal" }
   */
  export let layoutVariant = "horizontal";

  export let data;
  export let submitButtonId = `gtmEmailSignUp-${data.sys.id}`;

  // used in NewsletterList.svelte
  export let result;
  export let newsLetterListNoCampaignErrorMessage;

  /** @type {{collectPhoneNumbers?: boolean}} */
  const { collectPhoneNumbers } = getContext("featureFlags") ?? {};

  const fields = data?.fields || data;
  const imageFields = fields?.image?.fields;

  /** hard-coded list of email sign-ups to override with demForum2024 theme */
  const emailSignUpsToOverride = ["2LQpjz7He9uw2dUg3A2jNF"];
  const theme = emailSignUpsToOverride.includes(data.sys?.id) ? "lightGreen" : fields.theme;

  const useWebToCase = fields?.phoneLabel || fields?.noteLabel;

  $: formFields = [
    {
      id: "campaignId",
      name: SALESFORCE_CONSTANTS.fieldNames.campaignId,
      type: "hidden",
      value: fields?.campaignId
    },
    {
      id: "redirectUrl",
      name: SALESFORCE_CONSTANTS.fieldNames.redirectUrl,
      type: "hidden",
      value: getFullUrl(fields.redirectUrl, $page?.url?.origin)
    },
    {
      id: "subject",
      name: SALESFORCE_CONSTANTS.fieldNames.subject,
      type: "hidden",
      value: fields?.subject || fields.title
    },
    {
      id: "Source_URL__c",
      name: SALESFORCE_CONSTANTS.fieldNames.contactSourceUrl,
      type: "hidden",
      value: $page?.url?.href
    },
    {
      id: `form-view-${fields?.campaignId}-firstName`,
      name: SALESFORCE_CONSTANTS.fieldNames.firstName,
      type: "text",
      label: fields?.firstNameLabel,
      required: true,
      errorMessage: fields?.firstNameError
    },
    {
      id: `form-view-${fields?.campaignId}-lastName`,
      name: SALESFORCE_CONSTANTS.fieldNames.lastName,
      type: "text",
      label: fields?.lastNameLabel,
      required: true,
      errorMessage: fields?.lastNameError
    },
    {
      id: `form-view-${fields?.campaignId}-email`,
      name: SALESFORCE_CONSTANTS.fieldNames.email,
      type: "email",
      label: fields?.emailAddressFieldLabel,
      required: true,
      pattern: "[^@\\s]+@[^@\\s]+.[^@\\s]+",
      errorMessage: fields?.emailAddressError
    },
    fields?.phoneLabel && collectPhoneNumbers
      ? {
          id: "phone",
          name: SALESFORCE_CONSTANTS.fieldNames.phone,
          label: fields?.phoneLabel,
          type: "text", // pattern doesn't work for number input
          required: false,
          pattern: "[\\d]{10,15}",
          errorMessage: fields?.phoneError
        }
      : null,
    fields?.noteLabel
      ? {
          id: "note",
          name: SALESFORCE_CONSTANTS.fieldNames.description,
          label: fields?.noteLabel,
          type: "textarea",
          required: false,
          errorMessage: fields?.noteError
        }
      : null,
    {
      id: "termsOfService",
      type: "checkbox",
      label: fields?.termsOfServiceCheckboxLabelMarkdown,
      required: true,
      errorMessage: fields?.termsOfServiceError
    },
    {
      id: submitButtonId,
      type: "submit",
      label: fields?.submitButtonText
    }
  ].filter(Boolean);
</script>

<EmailSignupForm
  campaignId={fields?.campaignId}
  class={className}
  image={imageFields}
  {layoutVariant}
  title={fields?.title}
  description={fields?.description}
  {newsLetterListNoCampaignErrorMessage}
  titleConfirmationPage={fields?.successTitle}
  subTitleConfirmationPage={fields?.successSubtitle}
  descriptionConfirmationPage={fields?.successDescription}
  postSubmissionAction={fields?.postSubmissionAction}
  {formFields}
  {theme}
  {useWebToCase}
  bind:result
/>
