<script>
  import { getContext, setContext } from "svelte";
  import { GridContainer, List } from "@ObamaFoundation/of-design-system";

  /** @type { string } */
  let className = "";
  export { className as class };
  export let variant;
  export let data;

  const isContainerized = !!getContext("containerized");

  if (!isContainerized) {
    setContext("containerized", true);
  }
</script>

{#if isContainerized}
  <List {variant} {data} class={className} />
{:else}
  <GridContainer containerClass={className}>
    <List {variant} {data} />
  </GridContainer>
{/if}
