<script>
  import { getContext } from "svelte";
  import { GridContainer } from "@ObamaFoundation/of-design-system";

  /** @type { string } */
  let className = "";
  export { className as class };

  export let data;
  let htmlField = data.htmlSnippet || data.fields.htmlSnippet; // Header strips out fields and passes that directly

  const isContainerized = !!getContext("containerized");
</script>

{#if htmlField}
  {#if isContainerized}
    <div class={className}>
      <div class="justify-center flex">
        <!-- eslint-disable-next-line svelte/no-at-html-tags -->
        {@html htmlField}
      </div>
    </div>
  {:else}
    <GridContainer containerClass={className}>
      <div class="justify-center flex">
        <!-- eslint-disable-next-line svelte/no-at-html-tags -->
        {@html htmlField}
      </div>
    </GridContainer>
  {/if}
{/if}
