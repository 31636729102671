<script>
  /* eslint-disable no-console */
  import { textToId } from "$lib/utilities/dom-helpers";
  import { DynamicHeading } from "@ObamaFoundation/of-design-system";

  export let idOverride = "";
  export let text;
  let clazz = "";
  export { clazz as class };

  const id = idOverride || textToId(text);
  const classes = `focus:underline ${clazz} block`;
</script>

<DynamicHeading data-testid="heading" class={classes} {id} {...$$restProps}>
  {text}
</DynamicHeading>
