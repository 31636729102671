<script>
  import { setContext, getContext } from "svelte";
  import { ImageGrid } from "@ObamaFoundation/of-design-system";
  import { srcSetFromWidths, sizesForBreakpoints, SRCSET_WIDTHS } from "$lib/utilities";

  import { buildSrc } from "$lib/utilities/components/image-helpers";

  /** @type { string } */
  let className = "";
  export { className as class };
  export let images = [];
  export let imageOrientation = "landscape";

  let resizeParams = {};

  // Boolean used to ignore the Contentful Image API image resizing logic
  // Introduced to remove chromatic image flakiness caused by small differences in images
  let ignoreContentfulImgResize = getContext("ignoreContentfulImgResize");

  // Image sizes (widths) for available breakpoints.
  // These can be set using any unit, but we recommend using vw (50vw, 100vw, etc.)
  // If you don't set these, the image will be full width at all breakpoints.
  let widthsAt = { sm: "100vw", md: "50vw" };

  const sizes = sizesForBreakpoints(widthsAt);

  const imageGridImages = images.map((imageData) => {
    return {
      image: {
        src: buildSrc(imageData.fields?.imageAsset),
        alt: imageData.fields?.altText,
        srcset: !ignoreContentfulImgResize
          ? srcSetFromWidths(
              imageData.fields?.imageAsset?.fields?.file?.url,
              SRCSET_WIDTHS,
              resizeParams
            )
          : undefined,
        sizes,
        theme: imageData.fields?.theme
      },
      caption: imageData.fields?.caption
    };
  });

  setContext("containerized", true);
</script>

<ImageGrid class={className} images={imageGridImages} aspectRatio={imageOrientation} />
