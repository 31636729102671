<script>
  import { getContext } from "svelte";

  import { buildSrc, srcSetFromWidths, sizesForBreakpoints, SRCSET_WIDTHS } from "$lib/utilities";

  import { StaticMenu } from "@ObamaFoundation/of-design-system";

  export let data;

  export let resizeParams = {};

  // Boolean used to ignore the Contentful Image API image resizing logic
  // Introduced to remove chromatic image flakiness caused by small differences in images
  let ignoreContentfulImgResize = getContext("ignoreContentfulImgResize");

  // Image sizes (widths) for available breakpoints.
  // These can be set using any unit, but we recommend using vw (50vw, 100vw, etc.)
  // If you don't set these, the image will be full width at all breakpoints.
  let widthsAt = { sm: "50px" };
  const sizes = sizesForBreakpoints(widthsAt);

  let menuItems = data.fields.items?.map((item) => {
    return {
      label: item.fields.text,
      url: item.fields.url,
      imageSrc: buildSrc(item.fields?.image),
      srcset:
        !ignoreContentfulImgResize && item.fields?.image?.fields?.file?.url
          ? srcSetFromWidths(item.fields.image.fields.file.url, SRCSET_WIDTHS, resizeParams)
          : null,
      sizes
    };
  });

  /** @type { string } */
  let className = "";
  export { className as class };
</script>

{#if menuItems}
  <div class={className}>
    <StaticMenu links={menuItems} />
  </div>
{/if}
