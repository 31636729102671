<script>
  import { createWidget } from "@typeform/embed";
  import { v4 as uuid } from "@lukeed/uuid";
  import "@typeform/embed/build/css/widget.css";
  import { onMount } from "svelte";

  export let data;
  export let hideHeaders = false;
  export let hideFooter = false;
  export let lazy = false;
  export let enableSandbox = false;

  let id = data.fields.embedID;
  let generatedUUID = uuid();
  let containerId = `typeform-container-${generatedUUID}`;

  onMount(() => {
    createWidget(id, {
      container: document.querySelector(`#${containerId}`),
      hideHeaders,
      hideFooter,
      lazy,
      enableSandbox
    });
  });
</script>

<div id={containerId} class="h-[75vh] md:h-[50vh]" data-chromatic="ignore" />
