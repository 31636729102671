<script>
  import { onMount } from "svelte";
  import EmailSignupForm from "$lib/contentTypes/EmailSignupForm.svelte";
  import { setContext } from "svelte";
  // eslint-disable-next-line  @ObamaFoundation/of-svelte/no-stores
  import { writable } from "svelte/store";
  import { newsletterKeys } from "$lib/utilities/formFields";

  import { Checkbox, Rule, CenteredLayout } from "@ObamaFoundation/of-design-system";

  export let data;
  export let result = {};
  export let preSelectedNewsletters;
  let containerRef;

  let newsletterOptions = data.fields.optionsToInclude.map((x) => newsletterKeys[x]);
  /* Initialize the store to have a key representing each option and a value
    representing whether it's been selected. */
  let newsletterStore = {};
  newsletterOptions.forEach((option) => {
    newsletterStore[option.fieldProps.id] = false;
  });
  if (preSelectedNewsletters) {
    newsletterStore = preSelectedNewsletters;
  }
  let selectedNewsletters = writable(newsletterStore);

  let submitSuccessTextElement = undefined;

  const handleChange = (checkbox) => {
    const { detail } = checkbox;

    if (detail) {
      if (detail.id === "newsletters-select-all") {
        // Select or de-select all options.
        selectedNewsletters.update((state) => {
          Object.keys($selectedNewsletters).forEach((id) => {
            state[id] = detail.value;
          });
          return state;
        });
      } else {
        selectedNewsletters.update((state) => {
          // If select-all is checked and we unselect one option, also unselect select-all option.
          if (!detail.value && $selectedNewsletters["newsletters-select-all"]) {
            state["newsletters-select-all"] = false;
          }

          state[detail.id] = state[detail.id] === false;

          // Return true if all newsletters selected or are the select-all option.
          let allSelected = () =>
            Object.keys($selectedNewsletters).every(
              (selection) =>
                $selectedNewsletters[selection] || selection === "newsletters-select-all"
            );

          // If all options are selected, make sure select-all is selected.
          if (detail.value && !$selectedNewsletters["newsletters-select-all"] && allSelected()) {
            state["newsletters-select-all"] = detail.value;
          }
          return state;
        });
      }
    }
  };

  // If successfully registered, focus page at the top to display success message.
  $: if (result.status === "success") {
    setTimeout(() => {
      window.scrollTo({
        top: containerRef?.offsetTop || 0,
        behavior: "smooth"
      });
    }, 10);
  }

  $: setContext("selectedNewsletters", selectedNewsletters);

  let submitSuccessCampaignsSelected = [];
  $: submitSuccessCampaignsSelected = newsletterOptions.filter(
    // Filter for selected campaigns
    (newsletter) => {
      return (
        $selectedNewsletters[newsletter.fieldProps.name] &&
        newsletter.fieldProps.name !== "newsletters-select-all"
      );
    }
  );

  setContext("containerized", true);

  onMount(() => {
    if (result.status === "success") {
      submitSuccessTextElement.focus();
    }
  });
</script>

<CenteredLayout aria-live="polite">
  {#if result.status !== "success"}
    <div class="">
      {#each newsletterOptions as field}
        <Checkbox
          id={field.fieldProps.id}
          label={field.fieldProps.label}
          required={field.fieldProps.required}
          isChecked={$selectedNewsletters[field.fieldProps.id]}
          on:change={handleChange}
        />
        {#if field.fieldProps.fieldDetailsRichText}
          <p class="font-['chronicle-text'] body-md mt-6">
            {field.fieldProps.fieldDetailsRichText}
          </p>
        {/if}
        <Rule type="thin" theme="white" class="my-15 w-full" />
      {/each}
    </div>
    <EmailSignupForm
      data={data.fields.emailSignUpForm}
      submitButtonId={"gtmNewsletterList-other"}
      newsLetterListNoCampaignErrorMessage={data.fields.errorMsgNoneSelected}
      buttonVariant="primary"
      layoutVariant="vertical"
      bind:result
    />
  {:else}
    {#each submitSuccessCampaignsSelected as campaign}
      <div bind:this={containerRef}>
        <Checkbox
          id={campaign.fieldProps.id}
          label={campaign.fieldProps.label}
          disabled={true}
          isChecked={true}
        />
      </div>
      {#if campaign.fieldProps.fieldDetailsRichText}
        <p class="font-['chronicle-text'] body-md mt-6">
          {campaign.fieldProps.fieldDetailsRichText}
        </p>
      {/if}
      <Rule type="thin" theme="white" class="my-15 w-full" />
    {/each}
    <h2
      class="heading-md pb-25"
      bind:this={submitSuccessTextElement}
      tabindex="-1"
      aria-live="assertive"
    >
      Thank you for signing up for updates from the Obama Foundation!
    </h2>
  {/if}
</CenteredLayout>
