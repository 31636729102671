<script>
  import { CallToActionSticky } from "@ObamaFoundation/of-design-system";
  import { getCallToActionData } from "$lib/contentTypes/CallToActionComponent.svelte";

  export let data;

  const dataProps = { ...getCallToActionData(data), eyebrow: data.fields.description };
</script>

<CallToActionSticky {...dataProps} />
