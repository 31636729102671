<script>
  import { getContext } from "svelte";
  import BlockQuote from "$lib/components/BlockQuote.svelte";

  /** @type { string } */
  let className = "";
  export { className as class };

  export let data;

  const isContainerized = getContext("containerized");
</script>

<BlockQuote {...data.fields} class="{className} {isContainerized ? 'embedded-content' : ''}" />
