<script>
  export let title;
  export let src;
  export let id;
  export let scrolling = "no";
  export let allowfullscreen = true;

  let clazz = "w-full h-96 py-4";
  export { clazz as class };
</script>

<iframe
  class={clazz}
  {title}
  {id}
  {src}
  {scrolling}
  {allowfullscreen}
  loading="lazy"
  data-chromatic="ignore"
  {...$$restProps}
>
  <slot title="fallback" />
</iframe>
