<script>
  import { Donate } from "@ObamaFoundation/of-design-system";
  import { setContext } from "svelte";

  export let description;
  export let disclaimer;

  setContext("containerized", true);
  setContext("donateDescendant", true);
</script>

<div class="bg-sky-blue">
  <Donate {description} {disclaimer}>
    <slot name="title" slot="title" />
    <slot name="embed" slot="embed" />
  </Donate>
</div>
