<script context="module">
  import BlockQuote from "$lib/contentTypes/BlockQuote.svelte";
  import CallToActionComponent from "$lib/contentTypes/CallToActionComponent.svelte";
  import ContentGroup from "$lib/contentTypes/ContentGroup.svelte";
  import Contributors from "$lib/contentTypes/Contributors.svelte";
  import Donate from "$lib/contentTypes/Donate.svelte";
  import EmailSignupForm from "$lib/contentTypes/EmailSignupForm.svelte";
  import Embed from "$lib/contentTypes/Embed.svelte";
  import HTMLSnippet from "$lib/contentTypes/HTMLSnippet.svelte";
  import FundraisingGraph from "$lib/contentTypes/FundraisingGraph.svelte";
  import ImageComponent from "$lib/contentTypes/ImageComponent.svelte";
  import ImageGroup from "$lib/contentTypes/ImageGroup.svelte";
  import ItemPreviewList from "$lib/contentTypes/ItemPreviewList.svelte";
  import CenteredLayout from "$lib/contentTypes/CenteredLayout.svelte";
  import LinkComponent from "$lib/contentTypes/LinkComponent.svelte";
  import Menu from "$lib/contentTypes/Menu.svelte";
  import NewsletterList from "$lib/components/Form/NewsletterList.svelte";
  import SplitLayoutComponent from "$lib/contentTypes/SplitLayoutComponent.svelte";
  import Video from "$lib/contentTypes/Video.svelte";
  import WordCloud from "$lib/contentTypes/WordCloud.svelte";

  // TODO: Double-check child components are valid before launch
  //The section map is just the component map without section in it
  export const SECTION_COMPONENT_MAP = {
    blockQuote: BlockQuote,
    callToActionComponent: CallToActionComponent,
    contentGroup: ContentGroup,
    contributors: Contributors,
    donateComponent: Donate,
    emailSignup: EmailSignupForm,
    embed: Embed,
    fundraisingGraph: FundraisingGraph,
    htmlSnippet: HTMLSnippet,
    /** @deprecated */
    fundraiseUpElement: HTMLSnippet,
    imageComponent: ImageComponent,
    imageGroup: ImageGroup,
    itemPreviewList: ItemPreviewList,
    layout: CenteredLayout,
    linkComponent: LinkComponent,
    menu: Menu,
    newsletterList: NewsletterList,
    splitLayout: SplitLayoutComponent,
    video: Video,
    wordCloud: WordCloud
  };
</script>

<script>
  import { setContext } from "svelte";
  import RichText from "$lib/components/RichText.svelte";
  import Section from "$lib/components/Section.svelte";
  import { isExternalUrl } from "$lib/utilities/url-helpers";

  export let data;
  /** @type { string } */
  let className = "";
  export { className as class };

  const title = data.fields.headline;
  const theme = data.fields.theme;
  const cta = data.fields.callToAction
    ? {
        text: data.fields.callToAction?.fields?.text,
        url: data.fields.callToAction?.fields?.destination?.fields?.url,
        isExternal: isExternalUrl(data.fields.callToAction?.fields?.destination?.fields?.url)
      }
    : undefined;

  /**
   * I'm setting this context so that descendant components (especially LayoutComponents) can check the context to see
   * if they are descendants of a SectionComponent. Note that this context is only available to this component's descendants.
   */
  setContext("sectionDescendant", true);
</script>

<Section {title} {theme} {cta} class={className}>
  <RichText node={data.fields.body} componentMap={SECTION_COMPONENT_MAP} />
</Section>
