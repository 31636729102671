<script>
  import { GridContainer, Quote } from "@ObamaFoundation/of-design-system";
  import {
    altTextToDisplay,
    buildSrc,
    srcSetFromWidths,
    sizesForBreakpoints,
    SRCSET_WIDTHS
  } from "$lib/utilities";
  import { BREAK_POINTS } from "$lib/utilities/constants";
  import { getContext } from "svelte";

  export let quoteContent;
  export let quoteCaption = "";
  export let quoteImage = undefined;

  /** @type { "right" | "left "}*/
  export let imagePosition = "left";

  /** @type { string } */
  let className = "";
  export { className as class };

  export const quoteImageAltText = altTextToDisplay(quoteImage?.fields.altText);

  let resizeParams = {};

  // Boolean used to ignore the Contentful Image API image resizing logic
  // Introduced to remove chromatic image flakiness caused by small differences in images
  let ignoreContentfulImgResize = getContext("ignoreContentfulImgResize");

  // Image sizes (widths) for available breakpoints.
  // These can be set using any unit, but we recommend using vw (50vw, 100vw, etc.)
  // If you don't set these, the image will be full width at all breakpoints.
  let widthsAt = {};
  const sizes = sizesForBreakpoints(widthsAt);

  let screenWidth = 0;

  $: shouldUseMobileAsset = screenWidth < BREAK_POINTS.md && quoteImage?.fields?.mobileImageAsset;

  $: quoteImageSrc = buildSrc(
    shouldUseMobileAsset ? quoteImage?.fields?.mobileImageAsset : quoteImage?.fields.imageAsset
  );

  const isContainerized = !!getContext("containerized");

  $: srcset =
    !ignoreContentfulImgResize && quoteImage?.fields?.imageAsset?.fields?.file?.url
      ? srcSetFromWidths(
          shouldUseMobileAsset
            ? quoteImage.fields.mobileImageAsset.fields.file.url
            : quoteImage.fields.imageAsset.fields.file.url,
          SRCSET_WIDTHS,
          resizeParams
        )
      : null;

  $: quoteImageProps = quoteImageSrc
    ? {
        src: quoteImageSrc,
        alt: quoteImageAltText,
        srcset,
        sizes,
        theme: quoteImage.fields?.them
      }
    : undefined;

  // For when quotes are not in the root of the page
  const baseClass =
    "[&:not(:last-child)]:mb-15 [&:not(:last-child)]:md:mb-20 [&:not(:last-child)]:lg:mb-25 ";
</script>

<svelte:window bind:innerWidth={screenWidth} />

{#if isContainerized}
  <Quote
    class="{baseClass} {className}"
    text={quoteContent}
    caption={quoteCaption}
    {imagePosition}
    quoteImage={quoteImageProps}
    variant="inset"
  />
{:else}
  <GridContainer containerClass={className}>
    <Quote
      text={quoteContent}
      caption={quoteCaption}
      {imagePosition}
      quoteImage={quoteImageProps}
      variant="full"
    />
  </GridContainer>
{/if}
