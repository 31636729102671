export function createRichTextString(string, options) {
  const marks = options?.bold === true ? [{ type: "bold" }] : [];
  const value = string;
  return {
    data: {},
    content: [
      {
        data: {},
        content: [
          {
            data: {},
            marks,
            value,
            nodeType: "text"
          }
        ],
        nodeType: "paragraph"
      }
    ],
    nodeType: "document"
  };
}

export function createRichTextHyperlink(string, target = "#") {
  return {
    data: {},
    content: [
      {
        data: {},
        marks: [],
        value: "",
        nodeType: "text"
      },
      {
        data: {
          uri: target
        },
        content: [
          {
            data: {},
            marks: [],
            value: string,
            nodeType: "text"
          }
        ],
        nodeType: "hyperlink"
      },
      {
        data: {},
        marks: [],
        value: "",
        nodeType: "text"
      }
    ],
    nodeType: "document"
  };
}

export const removeQuotationMarks = (str) => {
  const newStr = str.replace(/["'“”‘’]/g, "");
  return newStr;
};
