import { SALESFORCE_CONSTANTS } from "$lib/utilities/constants";
import { textToId } from "../dom-helpers";
import { getFullUrl } from "../url-helpers";

/**
 * @typedef {"Salesforce" | "Infor"} FormProvider
 */

/**
 * @typedef {"split" | "stacked"} FormLayout
 */

/**
 * @typedef {Object} FormFieldProps
 *
 * @property {string} id
 * @property {string} label
 * @property {"text" | "email" | "phone" | "number" | "textarea" | "checkbox" | "radio" | "single-select" | "multi-select" | "date"} type
 * @property {string[]} [options]
 * @property {boolean} [required]
 * @property {string} [helpText]
 * @property {string} [errorMessage]
 *
 */

/**
 * @typedef {Object} FormFieldContentType
 *
 * @property {FormFieldProps} fields
 *
 */

/**
 * @typedef {Object} FormFieldGroupProps
 *
 * @property {string} label
 * @property {FormFieldContentType[]} fields
 *
 */
/**
 * @typedef {Object} FormFieldGroupContentType
 *
 * @property {FormFieldGroupProps} fields
 *
 */

/**
 * @typedef {Object} FormContentType
 *
 * @property {string} id
 * @property {FormProvider} provider
 * @property {FormLayout} layout
 * @property {string} theme
 * @property {string} title
 * @property {string} description
 * @property {(FormFieldContentType | FormFieldGroupContentType)[]} fields
 * @property {string} [submitButtonLabel]
 * @property {string} [confirmationTitle]
 * @property {string} [confirmationSubtitle]
 * @property {string} [confirmationDescription]
 * @property {Object} [confirmationCta]
 * @property {string} [redirectUrl]
 *
 */

/**
 *
 * @param {*} rawFormData
 * @param {*} currentUrl
 * @returns
 */
export function buildDefaultFormFields(rawFormData = {}, currentUrl) {
  const { id: formId, provider, title, submitButtonLabel, redirectUrl } = rawFormData;

  const defaultSalesforceFields = [
    {
      id: "campaignId",
      name: SALESFORCE_CONSTANTS.fieldNames.campaignId,
      type: "hidden",
      value: formId
    },
    {
      id: "redirectUrl",
      name: SALESFORCE_CONSTANTS.fieldNames.redirectUrl,
      type: "hidden",
      value: getFullUrl(redirectUrl, currentUrl?.origin)
    },
    {
      id: "subject",
      name: SALESFORCE_CONSTANTS.fieldNames.subject,
      type: "hidden",
      value: title
    },
    {
      id: "Source_URL__c",
      name: SALESFORCE_CONSTANTS.fieldNames.contactSourceUrl,
      type: "hidden",
      value: currentUrl?.href
    },
    {
      id: `gtmEmailSignUp-${formId}`,
      type: "submit",
      label: submitButtonLabel
    }
  ];

  let defaultFormFields = [];

  switch (provider) {
    case "Salesforce":
      // add default fields
      defaultFormFields.push(...defaultSalesforceFields);
      break;
    case "Infor":
    default:
      console.error(`Sorry, form provider not supported: ${provider}.`);
  }

  return defaultFormFields;
}

/**
 *
 * @param {*} rawFormData
 * @returns
 */
export function buildFormFields(rawFormData = {}) {
  const { id: formId, fields: rawFormFieldsOrFieldGroups = [] } = rawFormData;

  let transformedFormFields = [];
  const rawFormFields = rawFormFieldsOrFieldGroups.filter(
    (f) => f.sys.contentType.sys.id === "formField"
  );

  for (const formField of rawFormFields) {
    const type = formField.fields.type;

    let formFieldProps = {
      ...formField.fields,
      id: `form-view-${formId}-${textToId(formField.fields.label)}`,
      name: formField.fields.id
    };

    switch (type) {
      case "text":
      case "email":
      case "phone":
      case "checkbox":
      case "number":
      case "textarea":
        transformedFormFields.push(formFieldProps);
        break;
      case "multi-select":
        formFieldProps.options = formField.fields.options.map((opt) => ({
          name: formField.fields.id, // checkboxes w/ same name are grouped together
          id: `form-view-${formId}-${textToId(formField.fields.label)}-${textToId(opt)}`,
          label: opt
        }));
        transformedFormFields.push(formFieldProps);
        break;
      // TODO: not yet supported
      case "radio":
      case "single-select":
      case "date":
      default:
        console.error(`Sorry, input type not supported: ${type}.`);
    }
  }

  return transformedFormFields;
}

/**
 * Build the props for the Form Field Group inside a Form
 */
export function buildFormFieldGroups(rawFormData = {}) {
  const { id, fields: rawFormFieldsOrFieldGroups = [] } = rawFormData;
  return rawFormFieldsOrFieldGroups
    .filter((f) => f.sys.contentType.sys.id === "formFieldGroup")
    .map((formGroup) => ({
      label: formGroup.fields.label,
      fields: buildFormFields({
        id,
        fields: formGroup.fields.fields
      })
    }));
}

export function buildCta(confirmationCta) {
  let ctaProps = { text: "View all newsletters", url: "/newsletters" };
  if (confirmationCta) {
    if (confirmationCta.fields.htmlSnippet) {
      ctaProps = { htmlSnippet: confirmationCta.fields.htmlSnippet };
    } else {
      ctaProps = {
        text: confirmationCta.fields.text,
        url: confirmationCta.fields.destination.fields.url
      };
    }
  }

  return ctaProps;
}
