<script>
  import fundraisingGraph from "../assets/fundraisingGraph.png";

  import GraphImage from "$lib/components/GraphImage.svelte";

  export let headline = "Our $1.6B Goal";
  let alt =
    "A graphic that details the cost breakdown associated with the Obama Foundation's fundraising goal.";

  //// bg-blue-200 bg-marigold-200 bg-marigold-500 bg-cyan-500 bg-indigo-800
  let legendElements = [
    {
      label: "Center Construction",
      description: "$700M",
      color: "blue-200"
    },
    {
      label: "Center Startup",
      description: "$90M",
      color: "marigold-200"
    },
    {
      label: "Center Opening",
      description: "$40M",
      color: "marigold-500"
    },
    {
      label: "Endowment",
      description: "$470M",
      color: "cyan-500"
    },
    {
      label: "Obama Foundation Global Programming",
      description: "$320M",
      color: "indigo-800"
    }
  ];
</script>

<GraphImage
  data={{
    sys: {
      id: "fundraisingGraphImage"
    },
    fields: {
      headline,
      altText: alt,
      imageAsset: {
        fields: {
          file: {
            url: fundraisingGraph
          }
        }
      },
      legendElements
    }
  }}
/>
