<script>
  import { parseProductRootUrl } from "$lib/utilities/menu-helpers";
  import { TertiaryNavigation } from "@ObamaFoundation/of-design-system";
  import { onMount } from "svelte";

  export let data;

  $: currentRoute = "";

  onMount(() => {
    currentRoute = parseProductRootUrl(window.location);
  });

  $: items = data.fields.items.map(({ fields: { trigger, items } }) => ({
    headline: trigger.fields.text,
    links: items.map(({ fields: { text, url } }) => ({
      label: text,
      url
    }))
  }));
</script>

<TertiaryNavigation {items} {currentRoute} />
