<script>
  // @ts-check
  // eslint-disable-next-line  @ObamaFoundation/of-svelte/no-stores
  import { getContext } from "svelte";

  import { GridContainer, FormComponent } from "@ObamaFoundation/of-design-system";

  import {
    buildSrc,
    hackWebToCase,
    srcSetFromWidths,
    sizesForBreakpoints,
    SRCSET_WIDTHS
  } from "$lib/utilities";
  import { BREAK_POINTS } from "$lib/utilities/constants";
  import { SALESFORCE_CONSTANTS } from "$lib/utilities/constants";

  /** @type {string} e.g., salesforce campaign id */
  export let id;

  /** @type {import("$lib/utilities/contentTypes/form").FormProvider} provider*/
  export let provider = "Salesforce";

  /** The type of layout under the featured layout
   * @type {import("$lib/utilities/contentTypes/form").FormLayout}
   */
  export let layout = "split";

  export let theme;
  export let title;
  export let description;
  export let image;
  export let formProps = {};
  export let formFields;
  export let formFieldGroups;
  export let result = null;
  export let confirmation;

  /** @type { string } */
  let className = "";
  export { className as class };

  export let resizeParams = {};

  const isContainerized = !!getContext("containerized");

  // Boolean used to ignore the Contentful Image API image resizing logic
  // Introduced to remove chromatic image flakiness caused by small differences in images
  let ignoreContentfulImgResize = getContext("ignoreContentfulImgResize");

  // Image sizes (widths) for available breakpoints.
  // These can be set using any unit, but we recommend using vw (50vw, 100vw, etc.)
  // If you don't set these, the image will be full width at all breakpoints.
  let widthsAt = {};
  const sizes = sizesForBreakpoints(widthsAt);

  const Constants = {
    formSubmitErrorMessage: "Something went wrong..."
  };

  let screenWidth = 0;
  $: shouldUseMobileAsset = screenWidth < BREAK_POINTS.md && image?.mobileImageAsset;

  // TODO: extract to helper
  $: featuredImage = {
    src: buildSrc(shouldUseMobileAsset ? image?.mobileImageAsset : image?.imageAsset),
    alt: image?.altText,
    srcset:
      !ignoreContentfulImgResize && image?.imageAsset.fields.file
        ? srcSetFromWidths(
            shouldUseMobileAsset
              ? image?.mobileImageAsset.fields.file.url
              : image?.imageAsset.fields.file.url,
            SRCSET_WIDTHS,
            resizeParams
          )
        : null,
    sizes,
    theme: image?.theme
  };

  let formSubmitErrorMessage = "";
  let submitted = false;
  let isSubmitting = false;

  /**
   *
   * @param {CustomEvent} event
   */
  async function handleSalesforceSubmit(event) {
    const formValues = event.detail;

    formSubmitErrorMessage = "";

    try {
      isSubmitting = true;

      const redirectUrl = formValues[SALESFORCE_CONSTANTS.fieldNames.redirectUrl];

      result = await hackWebToCase(formValues);

      if (result?.status === "success") {
        if (redirectUrl) {
          window.location.href = redirectUrl;
          return;
        } else {
          submitted = true;

          const formContainer = document.getElementById(id);
          if (formContainer) {
            const top = formContainer.getBoundingClientRect().top;
            window.scroll(0, top + window.scrollY);
          }
        }
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log("Error submitting form:", err);
      formSubmitErrorMessage = err?.message || Constants.formSubmitErrorMessage;
    } finally {
      isSubmitting = false;
    }
  }

  /**
   *
   * @param {import("$lib/utilities/contentTypes/form").FormProvider} provider
   */
  function getSubmitHandler(provider) {
    const handlerMap = {
      Salesforce: handleSalesforceSubmit,
      Infor: () => {
        throw new Error("Not implemented");
      }
    };
    return handlerMap[provider];
  }
</script>

<svelte:window bind:innerWidth={screenWidth} />

{#if isContainerized}
  <FormComponent
    {layout}
    {title}
    {theme}
    {featuredImage}
    {description}
    {formProps}
    {formFields}
    {formFieldGroups}
    onSubmit={handleSalesforceSubmit}
    {isSubmitting}
    isFormSubmitSuccess={submitted}
    {formSubmitErrorMessage}
    {confirmation}
  />
{:else}
  {#key screenWidth}
    <GridContainer containerClass={className}>
      <FormComponent
        {id}
        {layout}
        {title}
        {theme}
        {featuredImage}
        {description}
        {formProps}
        {formFields}
        {formFieldGroups}
        onSubmit={getSubmitHandler(provider)}
        {isSubmitting}
        isFormSubmitSuccess={submitted}
        {formSubmitErrorMessage}
        {confirmation}
      />
    </GridContainer>
  {/key}
{/if}
