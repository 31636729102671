<script>
  import { Grid } from "@ObamaFoundation/of-design-system";
  import Embed from "$lib/contentTypes/Embed.svelte";

  /**
   * The list of embeds to render in the group
   * @type array
   */
  export let listItems;

  export let subGridColumns = { mobile: 4, tablet: 12, desktop: 9 };

  export let gridElement = "ul";

  export let gridClass = "";
</script>

<Grid {subGridColumns} {gridElement} {gridClass}>
  {#each listItems as listItem}
    <li class="col-full-width lg:col-span-3 lg:odd:col-start-auto">
      <Embed data={listItem} />
    </li>
  {/each}
</Grid>
