<script>
  import DonorSearch from "$lib/components/Contributors/DonorSearch.svelte";
  import DonorAccordion from "$lib/components/Contributors/DonorAccordion.svelte";
  import { getContext } from "svelte";
  import {
    getLowTierContributors,
    sortContributorsByCategory,
    getHighTierContributors
  } from "$lib/utilities";

  export let data;

  const contributors = getContext("contributors");

  const contributorsByCategory = sortContributorsByCategory(contributors);

  // assume the csv is pre-sorted by category and alphabetically
  const lowTierContributors = getLowTierContributors(contributorsByCategory);

  const highTierContributors = getHighTierContributors(contributorsByCategory);
</script>

{#if data.fields.contributionCategory === "Less Than $10,000"}
  <DonorSearch {lowTierContributors} />
{:else if data.fields.contributionCategory === "Greater Than or Equal To $10,000"}
  <DonorAccordion {highTierContributors} />
{/if}
