<script>
  export let type;
  let clazz = "";
  export { clazz as class };
  export const isSelfClosing = (nodeType) => ["img", "br", "hr"].includes(nodeType);
  export let textId;
</script>

{#if isSelfClosing(type)}
  <svelte:element this={type} class={clazz} {...$$restProps} />
{:else if type === "div"}
  <slot />
{:else}
  <svelte:element this={type} id={textId} class={clazz} {...$$restProps}>
    <slot />
  </svelte:element>
{/if}
