<script>
  export let data;

  export let captionId =
    data.fields.legendElements && data.fields.legendElements.length > 0 ? data.sys.id : null;
</script>

<div role="region" class="w-full py-48 lg:px-[5%] sm:px-0">
  <div class="w-full text-black text-size-20 font-bold lg:px-0 sm:px-24 pb-24">
    {data.fields.headline}
  </div>

  <figure
    class="w-full flex flex-row md:flex-nowrap sm:flex-wrap items-center sm:px-10 md:px-[5%]"
    aria-labelledby={captionId}
  >
    <div class="lg:max-w-[50%] md:w-1/2 sm:w-full m-auto lg:px-0 sm:px-[10%]">
      <img src={data.fields.imageAsset.fields.file.url} alt={data.fields.altText} />
    </div>
    {#if data.fields.legendElements}
      <figcaption
        id={captionId}
        role="list"
        class="lg:max-w-1/2 md:w-1/2 sm:w-full px-40 flex flex-col gap-16 justify-between sm:py-0 sm:pt-24 lg:py-40"
        aria-label="Graph legend"
      >
        {#each data.fields.legendElements as element}
          <div class="w-full flex flex-row items-start">
            <div class="bg-{element.color} !w-24 h-24 rounded-full" />
            <div
              role="listitem"
              class="text-black px-16 flex flex-col w-4/5"
              aria-label="Legend Item: {element.label} {element.description}"
            >
              <div class="font-bold">{element.label}</div>
              <div class="font-normal text-size-14">{element.description}</div>
            </div>
          </div>
        {/each}
      </figcaption>
    {/if}
  </figure>
</div>
