<script>
  import { Accordion, CenteredLayout } from "@ObamaFoundation/of-design-system";
  import DonorNames from "./DonorNames.svelte";

  export let highTierContributors = [];
  highTierContributors = highTierContributors.map((e) => {
    return {
      headline: e.category,
      body: DonorNames,
      bodyProps: { contributors: e.contributors }
    };
  });
</script>

<CenteredLayout title="$10,000+" class="mb-7.5 lg:mb-15">
  <Accordion items={highTierContributors} />
</CenteredLayout>
