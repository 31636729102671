<script>
  import { setContext } from "svelte";
  import BlockQuote from "$lib/contentTypes/BlockQuote.svelte";
  import ContentGroup from "$lib/contentTypes/ContentGroup.svelte";
  import EmailSignupForm from "$lib/contentTypes/EmailSignupForm.svelte";
  import Embed from "$lib/contentTypes/Embed.svelte";
  import FreeRichTextComponent from "$lib/contentTypes/FreeRichTextComponent.svelte";
  import HTMLSnippet from "$lib/contentTypes/HTMLSnippet.svelte";
  import ImageComponent from "$lib/contentTypes/ImageComponent.svelte";
  import ImageGroup from "$lib/contentTypes/ImageGroup.svelte";
  import ItemPreviewList from "$lib/contentTypes/ItemPreviewList.svelte";
  import Menu from "$lib/contentTypes/Menu.svelte";
  import SplitLayout from "$lib/components/SplitLayout.svelte";
  import SplitLayoutCallToAction from "$lib/components/SplitLayoutCallToAction.svelte";
  import LinkComponent from "$lib/contentTypes/LinkComponent.svelte";

  const SPLIT_LAYOUT_COMPONENT_MAP = {
    blockQuote: BlockQuote,
    callToActionComponent: SplitLayoutCallToAction,
    contentGroup: ContentGroup,
    emailSignup: EmailSignupForm,
    embed: Embed,
    linkComponent: LinkComponent,
    freeRichTextComponent: FreeRichTextComponent,
    htmlSnippet: HTMLSnippet,
    /** @deprecated */
    fundraiseUpElement: HTMLSnippet,
    imageComponent: ImageComponent,
    imageGroup: ImageGroup,
    itemPreviewList: ItemPreviewList,
    menu: Menu
  };

  /** @type { string } */
  let className = "";
  export { className as class };

  export let data;

  export let componentMap = SPLIT_LAYOUT_COMPONENT_MAP;

  const { formFactor, sticky, itemsLeft, itemsRight } = data.fields;

  const getComponent = (typename) => {
    let component = componentMap[typename];
    if (!component) {
      // eslint-disable-next-line no-console
      console.warn(
        `Component missing in SplitLayout component map: ${typename}. Valid components include: ${Object.keys(
          componentMap
        ).join(", ")}`
      );
    }

    return component;
  };

  setContext("containerized", true);
  setContext("splitLayoutVariant", formFactor);
</script>

<SplitLayout isSticky={sticky} variant={formFactor} class={className}>
  <div slot="leftComponent" class={sticky ? "lg:h-full" : ""}>
    {#each itemsLeft as item}
      {@const typename = item.sys.contentType.sys.id}
      <svelte:component
        this={getComponent(typename)}
        slot="leftComponent"
        data={item}
        {...item}
        class={className}
      />
    {/each}
  </div>
  <div slot="rightComponent">
    {#each itemsRight as item}
      {@const typename = item.sys.contentType.sys.id}
      {#if typename === "freeRichTextComponent"}
        <FreeRichTextComponent
          data={item}
          componentMap={SPLIT_LAYOUT_COMPONENT_MAP}
          slot="rightComponent"
          class="embedded-content"
        />
      {:else}
        <svelte:component
          this={getComponent(typename)}
          data={item}
          {...item}
          class="embedded-content"
        />
      {/if}
    {/each}
  </div>
</SplitLayout>
