<script>
  import SearchableList from "$lib/components/SearchableList.svelte";

  export let lowTierContributors;
  export let formSubmitErrorMessage;
  export let errorId;

  $: filteredItems = [...lowTierContributors];
  function onSearch(event) {
    // handles error for empty search input
    let searchedName = event.detail["donorSearch-searchableList-searchBar"];
    filteredItems = lowTierContributors.filter(({ name }) =>
      name?.toLowerCase().includes(searchedName.toLowerCase())
    );
    // handles error when no results are found in search
    if (filteredItems.length === 0) {
      formSubmitErrorMessage = "No result found.";
      errorId = "donorSearch-error-noResults";
    } else {
      // clear error
      formSubmitErrorMessage = "";
      errorId = "";
    }
  }

  function resetContributorList() {
    formSubmitErrorMessage = "";
    filteredItems = [...lowTierContributors];
  }
</script>

<div id="lowTierContributorList">
  <SearchableList
    items={filteredItems}
    on:submit={onSearch}
    on:clear={resetContributorList}
    formName={"donorSearch"}
    {formSubmitErrorMessage}
  />
</div>
