<script>
  import { setContext } from "svelte";
  import { SplitLayout } from "@ObamaFoundation/of-design-system";

  /** @type { string } */
  let className = "";
  export { className as class };
  export let isSticky = false;
  export let variant;

  setContext("splitLayoutDescendant", true);
  setContext("containerized", true);
</script>

<SplitLayout {isSticky} {variant} class={className}>
  <div slot="left" class={isSticky ? "lg:h-full" : ""}>
    <slot name="leftComponent" />
  </div>
  <div slot="right">
    <slot name="rightComponent" />
  </div>
</SplitLayout>
