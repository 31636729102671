<script context="module">
  import BlockQuote from "$lib/contentTypes/BlockQuote.svelte";
  import ContentGroup from "$lib/contentTypes/ContentGroup.svelte";
  import Embed from "$lib/contentTypes/Embed.svelte";
  import FreeRichTextComponent from "$lib/contentTypes/FreeRichTextComponent.svelte";
  import ImageComponent from "$lib/contentTypes/ImageComponent.svelte";
  import Video from "$lib/contentTypes/Video.svelte";

  export const LAYOUT_COMPONENT_MAP = {
    blockQuote: BlockQuote,
    contentGroup: ContentGroup,
    embed: Embed,
    freeRichTextComponent: FreeRichTextComponent,
    imageComponent: ImageComponent,
    video: Video
  };
</script>

<script>
  import { CenteredLayout } from "@ObamaFoundation/of-design-system";
  import { getContext, setContext } from "svelte";
  import {
    buildSrc,
    altTextToDisplay,
    srcSetFromWidths,
    sizesForBreakpoints
  } from "$lib/utilities/components/image-helpers";
  import { SRCSET_WIDTHS } from "$lib/utilities";
  import { isExternalUrl } from "$lib/utilities/url-helpers";

  /** @type { string } */
  let className = "";
  export { className as class };
  export let data;

  const getComponent = (typename) => {
    let component = LAYOUT_COMPONENT_MAP[typename];
    if (!component) {
      // eslint-disable-next-line no-console
      console.warn(`Component missing in Layout component map: ${typename}`);
    }

    return component;
  };

  //Regardless, everything should be containerized from here down.
  setContext("containerized", true);

  const { header, items, link } = data.fields;

  const linkImage = link?.fields.image ?? link?.fields.destination?.fields?.image;

  const linkCard = link
    ? {
        title: link.fields.destination?.fields.name,
        cta: {
          text: link.fields.text,
          url: link.fields.destination?.fields.url,
          isExternal: isExternalUrl(link.fields.destination?.fields.url)
        },
        featuredImage: linkImage
          ? {
              src: buildSrc(linkImage),
              alt:
                altTextToDisplay(link.fields.altText) ??
                altTextToDisplay(link.fields.destination?.fields.image),
              srcset: srcSetFromWidths(linkImage?.fields?.file.url, SRCSET_WIDTHS),
              sizes: sizesForBreakpoints({ sm: "50vw", md: "35vw", lg: "25vw" })
            }
          : null,
        theme: link.fields.destination?.fields.theme
      }
    : null;

  const isSectionDescendant = !!getContext("containerized");
  /* Add spacing above if placed directly under section rule */
  const sectionChildClasses = isSectionDescendant ? " lg:[&:nth-child(2)]:pt-7.5" : "";
</script>

<CenteredLayout title={header} {linkCard} class="centered-layout {className}{sectionChildClasses}">
  {#each items as item}
    {@const typename = getComponent(item.sys.contentType.sys.id)}
    <svelte:component this={typename} data={item} {...item} class="embedded-content" />
  {/each}
</CenteredLayout>
