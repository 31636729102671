export const sharedProps = {
  type: "checkbox",
  required: false
};

export const EMAIL_ADDRESS_ERROR_MESSAGE = "Error: Must be a valid email address";
export const ONE_OR_MORE_FAILURES_ERROR_MESSAGE =
  "Error: One or more of the newsletters you selected is not available at the moment.";
export const GENERIC_EMAIL_SIGNUP_SALESFORCE_ERROR_MESSAGE =
  "We are experiencing issues with our subscription system. Please try again or come back soon to complete your sign-up.";

// Future work: Move these ids to Contentful so they can be adjusted as needed.
export const campaignIds = {
  "newsletters-select-goa": "70136000000z93N",
  "newsletters-select-mbka": "70136000000z93N",
  "newsletters-select-opc": "7013p000001u2InAAI",
  "newsletters-select-general": "70136000000z93N",
  "newsletters-select-chicago": "7011R000000v7Ry"
};

// Given an array of campaign ids, return an array of user friendly names of the corresponding campaigns.
// Multiple campaigns may share a campaign id, returns all user friendly names associated with
// each campaign. However, the array of returned names is de-duped.
export function getUserFriendlyCampaignsFromIds(valueArr) {
  let userFriendlies = new Set();
  let names = Object.keys(campaignIds).filter((key) => valueArr.includes(campaignIds[key]));
  names.forEach((name) => userFriendlies.add(getUserFriendlyCampaignFromName(name)));
  return Array.from(userFriendlies);
}

// Given the name ("newsletter-select-<unique>") of a newsletter, return the user-friendly label
// of that campaign.
export function getUserFriendlyCampaignFromName(name) {
  let userFriendly = Object.keys(newsletterKeys).find(
    (key) => newsletterKeys[key].fieldProps.name === name
  );
  return userFriendly ?? "";
}

/*
  State is an object with the same keys as the campaignIds object with true or false
  indicating whether they have been selected.
  Returns: CSV string of campaignIds
*/
export function listCampaignIdsFromState(state) {
  let ids = new Set();
  for (let newsletter in state) {
    if (state[newsletter] && campaignIds[newsletter]) {
      ids.add(campaignIds[newsletter]);
    }
  }
  return Array.from(ids).join();
}

export const newsletterKeys = {
  "Sign up for all newsletters": {
    fieldType: "checkboxField",
    fieldProps: {
      ...sharedProps,
      name: `newsletters-select-all`,
      label: "Sign up for all newsletters",
      labelClass: "font-bold",
      id: `newsletters-select-all`
    }
  },
  "The Girls Opportunity Alliance": {
    fieldType: "checkboxField",
    fieldProps: {
      ...sharedProps,
      name: `newsletters-select-goa`,
      label: "The Girls Opportunity Alliance",
      labelClass: "font-bold",
      fieldDetailsRichText:
        "When girls get the opportunities they deserve, amazing things start to happen. Follow the Girls Opportunity Alliance to help adolescent girls and the grassroots leaders working to educate them.",
      id: `newsletters-select-goa`
    }
  },
  "My Brother's Keeper Alliance": {
    fieldType: "checkboxField",
    fieldProps: {
      ...sharedProps,
      name: `newsletters-select-mbka`,
      label: "My Brother's Keeper Alliance",
      labelClass: "font-bold",
      fieldDetailsRichText:
        "The MBK Alliance focuses on building safe and supportive communities for boys and young men of color where they feel valued and have clear pathways to opportunity.",
      id: `newsletters-select-mbka`
    }
  },
  "The Obama Presidential Center": {
    fieldType: "checkboxField",
    fieldProps: {
      ...sharedProps,
      name: `newsletters-select-opc`,
      label: "The Obama Presidential Center",
      labelClass: "font-bold",
      fieldDetailsRichText:
        "Be the first to know about planned exhibits, construction updates, and events related to the Obama Presidential Center, a world-class museum and public gathering space that celebrates our nation’s first African American President and First Lady on the South Side of Chicago.",
      id: `newsletters-select-opc`
    }
  },
  "General News and Updates": {
    fieldType: "checkboxField",
    fieldProps: {
      ...sharedProps,
      name: `newsletters-select-general`,
      label: "General News and Updates",
      labelClass: "font-bold",
      fieldDetailsRichText:
        "President and Mrs. Obama believe the most important thing they can do now is pass the torch of leadership on to a new generation. You’ll get updates about our global network of emerging leaders and the inspiring work they’re doing around the world to change their communities -and our world - for the better.",
      id: `newsletters-select-general`
    }
  },
  Chicago: {
    fieldType: "checkboxField",
    fieldProps: {
      ...sharedProps,
      name: `newsletters-select-chicago`,
      label: "Chicago",
      labelClass: "font-bold",
      fieldDetailsRichText:
        "There are many exciting things happening at the Obama Foundation—across the country and the world, but some of the most important work is happening right here in Chicago. We're building a new landmark for the South Side and economic engine for the city with the Obama Presidential Center, in addition to launching new programming, hosting community events, and driving workforce efforts on our beloved South Side.",
      id: `newsletters-select-chicago`
    }
  }
};
