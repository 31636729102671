<script>
  import { getContext, setContext } from "svelte";
  import { Accordion, GridContainer } from "@ObamaFoundation/of-design-system";

  /** @type { string } */
  let className = "";
  export { className as class };
  export let items;

  const isContainerized = !!getContext("containerized");
  if (!isContainerized) {
    setContext("containerized", true);
  }
</script>

{#if isContainerized}
  <Accordion titleTag="h3" {items} class={className} />
{:else}
  <GridContainer containerClass={className}>
    <Accordion titleTag="h3" {items} class={className} />
  </GridContainer>
{/if}
