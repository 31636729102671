<script>
  import LoadingIndicator from "$lib/components/LoadingIndicator.svelte";
  import { onMount } from "svelte";

  export let data;

  onMount(() => {
    window.twttr && window.twttr.widgets.load();
  });
</script>

<svelte:head>
  <script
    async={true}
    defer={false}
    src="https://platform.twitter.com/widgets.js"
    charset="utf-8"
  ></script>
</svelte:head>

<div class="w-full max-w-[360px] mx-auto" data-chromatic="ignore">
  <blockquote class="twitter-tweet" data-theme="light" data-width="360">
    <a href={data.fields.url}>
      <LoadingIndicator
        containerClasses="justify-center"
        spinnerClasses="w-[2rem] h-[2rem]"
        screenreaderText="Loading a tweet"
      />
    </a>
  </blockquote>
</div>
