import { SALESFORCE_CONSTANTS } from "../constants";
import {
  getUserFriendlyCampaignsFromIds,
  EMAIL_ADDRESS_ERROR_MESSAGE,
  ONE_OR_MORE_FAILURES_ERROR_MESSAGE,
  GENERIC_EMAIL_SIGNUP_SALESFORCE_ERROR_MESSAGE
} from "../formFields";

/**
 *
 * @param {Object} params
 * @returns
 */
export async function postToSalesforce({ pageUrl, campaignIDs, firstname, lastname, email }) {
  const headers = { "Content-Type": "application/x-www-form-urlencoded" };
  const params = new URLSearchParams({
    leadsource: "web",
    contactsource: pageUrl,
    firstname,
    lastname,
    email,
    campaignids: campaignIDs
  }).toString();

  try {
    const resp = await fetch(`${SALESFORCE_CONSTANTS.customHandlerEndpoint}?${params}`, {
      method: "post",
      headers
    });

    if (resp.ok) {
      const body = await resp.json();
      // Validation errors from Salesforce Cloud Page are formatted with a 200 response,
      // but the bodies contain a status object with value `fail` and descriptive error msg.
      if (body.status === "fail") {
        // If one or more campaigns has failed, the ids are returned as a CSV the response body as `failedCampaignIds`.
        // To make a user-friendly error message, we translate the campaign ids to user friendly names.
        let failedCampaignIds = body.failedCampaignIds.split(",");
        let failedCampaigns = getUserFriendlyCampaignsFromIds(failedCampaignIds);
        throw new Error(body.msg, { cause: failedCampaigns.join(", ") });
      }
      return { ...body };
    } else {
      throw new Error(`HTTP Error: ${resp.statusCode}`);
    }
  } catch (err) {
    /* eslint-disable no-console*/
    console.error(err);
    let errorMsg = err.toString();
    // Error is unknown. Provide generic error message.
    if (
      errorMsg !== EMAIL_ADDRESS_ERROR_MESSAGE &&
      errorMsg !== ONE_OR_MORE_FAILURES_ERROR_MESSAGE
    ) {
      errorMsg = GENERIC_EMAIL_SIGNUP_SALESFORCE_ERROR_MESSAGE;
    }
    // Adjust Salesforce Error and add failure details
    if (errorMsg === ONE_OR_MORE_FAILURES_ERROR_MESSAGE) {
      errorMsg = `We are experiencing issues with the following newsletters. Please try again or come back soon to sign up: ${err.cause}`;
    }
    throw new Error(`${errorMsg}`);
  }
}

/**
 * when using Salesforce web-to-case endpoint,
 * this helper function posts to Salesforce web-to-case endpoint AND bypass the undesired redirect
 *
 * @param {Object} fields
 * @returns
 */
export async function hackWebToCase(fields) {
  // create an invisible iframe as form target: https://developer.mozilla.org/en-US/docs/Web/HTML/Element/form#target
  const virtualFormTarget = "virtual-form-target";
  if (!document.getElementById(virtualFormTarget)) {
    const iframe = document.createElement("iframe");
    iframe.id = virtualFormTarget;
    iframe.name = virtualFormTarget;
    iframe.src = "about:blank";
    iframe.style.display = "none";
    document.body.appendChild(iframe);
  }

  // create hidden form
  const form = document.createElement("form");
  form.method = "POST";
  form.action = SALESFORCE_CONSTANTS.webToCaseEndPoint;
  Object.keys(fields).forEach((fieldName) => {
    const theInput = document.createElement("input");
    theInput.name = fieldName;
    theInput.value = fields[fieldName];
    theInput.setAttribute("type", "hidden");
    form.appendChild(theInput);
  });
  document.body.appendChild(form);

  // trigger form submission and send response to virtual target
  form.target = virtualFormTarget;
  form.submit();

  // form submission doesn't actually return anything, so create mock response
  return {
    status: "success"
  };
}
