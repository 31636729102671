export function formatDate(str, options = {}) {
  if (str) {
    const defaultOptions = {
      year: "numeric",
      month: "long",
      day: "numeric",
      timeZone: "America/Chicago",
      timeZoneName: "short",
      hour: "numeric",
      minute: "numeric"
    };
    const date = new Date(str);
    if (date == "Invalid Date") {
      return str; // pre-formatted, non-standard, or otherwise invalid date string
    }

    return date.toLocaleString("en", { ...defaultOptions, ...options });
  }

  return "";
}
