<script>
  // eslint-disable-next-line  @ObamaFoundation/of-svelte/no-stores
  import { page } from "$app/stores";

  import { formatDate } from "$lib/utilities/date";
  import { isExternalUrl } from "$lib/utilities/url-helpers";

  import { Link } from "@ObamaFoundation/of-design-system";

  export let url = "";
  export let title;
  export let timestamp = "";
  $: isExternal = isExternalUrl(url, $page?.url?.origin);
</script>

<div class="relative after:absolute after:bg-black after:h-[1px] after:w-full pb-15">
  <h2 class="heading-xs pb-7.5">{title}</h2>
  <p class="font-['chronicle-text'] body-sm pb-7.5">{formatDate(timestamp)}</p>
  <Link href={url} label="Learn more" {isExternal} variant="small" class="pb-7.5" />
</div>
