<script>
  import Button from "$lib/components/Button.svelte";
  import Link from "$lib/components/Link.svelte";

  export let data;
  export let id;

  let TYPE_MAP = {
    textLink: Link,
    outlinedLink: Button,
    filledLink: Button
  };

  let PROPS_MAP = {
    textLink: {
      url: data.fields.destination?.fields.url,
      label: data.fields.text,
      variant: "large"
    },
    outlinedLink: {
      url: data.fields.destination?.fields.url,
      label: data.fields.text,
      variant: "outline"
    },
    filledLink: {
      url: data.fields.destination?.fields.url,
      label: data.fields.text,
      variant: "filled"
    }
  };

  const type = data.fields.type ?? "textLink";
  const component = TYPE_MAP[type];
  const props = PROPS_MAP[type] || {};
</script>

<!-- `embedded-content` should have no impact outside of rich text body. -->
<svelte:component this={component} on:click on:urlDispatch {...props} {id} class="rich-text" />
