<script>
  import { isExternalUrl, correctedUrl } from "$lib/utilities/url-helpers";
  import { Button, GridContainer } from "@ObamaFoundation/of-design-system";
  // eslint-disable-next-line  @ObamaFoundation/of-svelte/no-stores
  import { page } from "$app/stores";

  export let label;
  export let id;
  export let url;
  export let disabled;
  export let size;
  export let type;
  export let variant = "outline";

  let className = "";
  export { className as class };

  export let arialLabel;
  export let haspopup = false;
  export let expanded = false;

  let isExternal;
  let linkToRender;
  $: {
    isExternal = isExternalUrl(url, $page?.url?.origin);
    linkToRender = correctedUrl(url);
  }
</script>

<GridContainer containerClass={className}>
  <div>
    <Button
      {id}
      {label}
      {variant}
      {disabled}
      {size}
      on:click
      buttonClass="justify-center"
      aria-label={arialLabel}
      aria-haspopup={haspopup}
      aria-expanded={haspopup ? expanded : null}
      type={url ? undefined : type}
      url={linkToRender}
      target={isExternal ? "_blank" : null}
      rel={isExternal ? "noopener noreferrer" : null}
    >
      <slot />
    </Button>
  </div>
</GridContainer>
