<script>
  import Donate from "$lib/components/Donate.svelte";
  import HTMLSnippet from "$lib/contentTypes/HTMLSnippet.svelte";
  import WordCloud from "$lib/contentTypes/WordCloud.svelte";

  export let data;
</script>

<div class="embedded-content">
  <Donate {...data.fields}>
    <slot name="title" slot="title">
      <WordCloud data={data.fields.title} />
    </slot>
    <slot name="embed" slot="embed">
      <div class="lg:flex lg:justify-end">
        <HTMLSnippet data={data.fields.htmlSnippet} />
      </div>
    </slot>
  </Donate>
</div>
