<script>
  import { getContext, setContext } from "svelte";
  import { GridContainer, ListQA } from "@ObamaFoundation/of-design-system";

  /** @type { string } */
  let className = "";
  export { className as class };
  export let items;

  const isContainerized = !!getContext("containerized");

  if (!isContainerized) {
    setContext("containerized", true);
  }
</script>

{#if isContainerized}
  <ListQA titleTag="h3" {items} class={className} />
{:else}
  <GridContainer containerClass={className}>
    <ListQA titleTag="h3" {items} />
  </GridContainer>
{/if}
