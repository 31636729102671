import { FEATURED_IMAGES_POSITIONS, ITEM_PREVIEW_LIST_FORMS } from "$lib/utilities/constants";
import { correctedUrl, isExternalUrl } from "$lib/utilities/url-helpers";
import {
  buildSrc,
  srcSetFromWidths,
  SRCSET_WIDTHS,
  getClosestAspectRatioClass,
  mapTags
} from "$lib/utilities";
import { BREAK_POINTS } from "$lib/utilities/constants";
import { STORY_CARD_ASPECT_RATIO_CLASSES } from "$lib/utilities/image";

export let resizeParams = {};

const DEFAULT_STORY_CARD_THEME = "lightGrey";

const getStoryCardTheme = (theme) => {
  return theme ?? DEFAULT_STORY_CARD_THEME;
};

const getStoryCardTags = (item) => {
  let tags = item.contentTags;

  if (item.sourceTagItemId) {
    tags = mapTags(
      item.contentTags.filter((tag) => tag.sys.id !== item.sourceTagItemId),
      2
    );
  } else {
    tags = mapTags(item.contentTags, 2);
  }

  return tags;
};

const getStoryCardPropsByContentType = (item, screenWidth) => {
  let shouldUseMobileAsset =
    screenWidth < BREAK_POINTS.md && item.previewImageComponent?.fields.mobileImageAsset;
  switch (item.contentType) {
    case "navTarget": {
      return {
        title: item.text,
        description: item.description,
        imageAspectRatioClasses: item.image
          ? getClosestAspectRatioClass(
              item.image?.fields?.file?.details?.image?.width,
              item.image?.fields?.file?.details?.image?.height,
              STORY_CARD_ASPECT_RATIO_CLASSES
            )
          : null,
        featuredImage: item.image
          ? {
              alt: item.image?.fields?.title,
              src: item.image?.fields?.file.url,
              srcset: srcSetFromWidths(item.image?.fields?.file.url, SRCSET_WIDTHS, resizeParams)
            }
          : null,
        cardUrl: correctedUrl(item.url),
        cta: {
          url: correctedUrl(item.url),
          isExternal: isExternalUrl(item.url),
          text: "Learn more",
          id: `cta-${item.id}`
        },
        theme: item.isThemed ? getStoryCardTheme(item.theme) : undefined,
        tags: []
      };
    }
    case "pageData": {
      return {
        title: item.headline,
        description: item.previewText,
        imageAspectRatioClasses: item.previewImageComponent
          ? getClosestAspectRatioClass(
              item.previewImageComponent?.fields?.imageAsset?.fields?.file?.details?.image?.width,
              item.previewImageComponent?.fields?.imageAsset?.fields?.file?.details?.image?.height,
              STORY_CARD_ASPECT_RATIO_CLASSES
            )
          : null,
        featuredImage: item.previewImageComponent
          ? {
              alt: item.previewImageComponent?.fields?.altText ?? "",
              src: buildSrc(
                shouldUseMobileAsset
                  ? item.previewImageComponent?.fields?.mobileImageAsset
                  : item.previewImageComponent?.fields?.imageAsset
              ),
              srcset: srcSetFromWidths(
                shouldUseMobileAsset
                  ? item.previewImageComponent?.fields?.mobileImageAsset.fields.file.url
                  : item.previewImageComponent?.fields?.imageAsset.fields.file.url,
                SRCSET_WIDTHS,
                resizeParams
              ),
              theme: item.previewImageComponent?.fields?.theme
            }
          : null,
        cardUrl: correctedUrl(item.relativeUrl),
        cta: {
          url: correctedUrl(item.relativeUrl),
          text: "Learn more",
          id: item.sys?.id ? `cta-${item.sys?.id}` : `cta-${item.id}`
        },
        theme: item.isThemed ? getStoryCardTheme(item.theme) : undefined,
        tags: getStoryCardTags(item)
      };
    }
    default:
      return {};
  }
};

const DEFAULT_LINK_CARD_THEME = "skyBlue";

const getLinkCardTheme = (theme) => {
  return theme ?? DEFAULT_LINK_CARD_THEME;
};

const getLinkCardPropsByContentType = (item) => {
  switch (item.contentType) {
    case "navTarget": {
      return {
        title: item?.text,
        description: item.description,
        featuredImage: {
          alt: item.image?.fields?.title,
          src: item.image?.fields?.file.url,
          srcset: srcSetFromWidths(item.image?.fields?.file.url, SRCSET_WIDTHS, resizeParams)
        },
        cta: {
          url: correctedUrl(item.url),
          isExternal: isExternalUrl(item.url),
          text: "Learn more",
          id: `cta-${item.id}`
        },
        titleTag: "h2",
        theme: getLinkCardTheme(item.theme)
      };
    }
    case "pageData": {
      return {
        title: item.headline,
        description: item.previewText,
        featuredImage: {
          alt: item.previewImageComponent?.fields?.imageAsset?.fields?.title,
          src: item.previewImageComponent?.fields?.imageAsset?.fields?.file.url,
          srcset: srcSetFromWidths(
            item.previewImageComponent?.fields?.imageAsset?.fields?.file.url,
            SRCSET_WIDTHS,
            resizeParams
          ),
          theme: item.previewImageComponent?.fields?.theme
        },
        cta: {
          url: correctedUrl(item?.relativeUrl),
          isExternal: isExternalUrl(item?.relativeUrl),
          text: "Learn more",
          id: `cta-${item.id}`
        },
        titleTag: "h2",
        theme: getLinkCardTheme(item.theme)
      };
    }
    default:
      return {};
  }
};

const getLinkTitleOnlyCardPropsByContentType = (item) => {
  switch (
    item.contentType // correctedUrl used to relativize url of each item in list
  ) {
    case "navTarget": {
      return {
        title: item.text,
        cta: {
          url: correctedUrl(item.url),
          isExternal: isExternalUrl(item.url),
          text: "Learn more",
          id: `cta-${item.id}`
        },
        titleTag: "h2",
        theme: getLinkCardTheme(item.theme)
      };
    }
    case "pageData": {
      return {
        title: item.headline,
        cta: {
          url: correctedUrl(item.relativeUrl),
          isExternal: isExternalUrl(item.relativeUrl),
          text: "Learn more",
          id: `cta-${item.id}`
        },
        titleTag: "h2",
        theme: getLinkCardTheme(item.theme)
      };
    }
    default: {
      return {};
    }
  }
};

const getHeadlineCardPropsByContentType = (item) => {
  switch (item.contentType) {
    case "navTarget": {
      return {
        title: item.text,
        url: correctedUrl(item.url),
        description: item.name
      };
    }
    case "pageData": {
      return {
        timestamp: item.legacyPublishDate || item.sys?.createdAt,
        title: item.headline,
        url: correctedUrl(item.relativeUrl),
        description: item.previewText
      };
    }
    default:
      break;
  }
};

/**
 * Generate the props for each card component
 * @param {Object} item - data representing each card in the IPL
 * @param {string} itemsFormFactor - the itemsFormFactor for the IPL
 * @param {number} screenWidth - the width of the screen. Used to determine whether to use a desktop or mobile image asset.
 * @returns the props for the card component
 */
export const createItemProps = (item, itemsFormFactor, screenWidth) => {
  switch (itemsFormFactor) {
    case ITEM_PREVIEW_LIST_FORMS.Headline:
      return getHeadlineCardPropsByContentType(item);

    case ITEM_PREVIEW_LIST_FORMS.Link2:
    case ITEM_PREVIEW_LIST_FORMS.Link2Feature:
    case ITEM_PREVIEW_LIST_FORMS.Link3:
    case ITEM_PREVIEW_LIST_FORMS.Link4:
      return getLinkCardPropsByContentType(item);

    case ITEM_PREVIEW_LIST_FORMS.Link4TitleOnly:
      return getLinkTitleOnlyCardPropsByContentType(item);

    case ITEM_PREVIEW_LIST_FORMS.Story3FeatureImageLeft:
      return {
        ...getStoryCardPropsByContentType(item, screenWidth),
        imagePosition: FEATURED_IMAGES_POSITIONS.Left
      };

    case ITEM_PREVIEW_LIST_FORMS.Story3FeatureImageRight:
      return {
        ...getStoryCardPropsByContentType(item, screenWidth),
        imagePosition: FEATURED_IMAGES_POSITIONS.Right
      };

    case ITEM_PREVIEW_LIST_FORMS.Story2:
    case ITEM_PREVIEW_LIST_FORMS.Story3:
    case ITEM_PREVIEW_LIST_FORMS.Story3FeatureTwoThirds:
    case ITEM_PREVIEW_LIST_FORMS.Story3FeatureHalf:
      return getStoryCardPropsByContentType(item, screenWidth);

    case ITEM_PREVIEW_LIST_FORMS.Avatar4:
    case ITEM_PREVIEW_LIST_FORMS.Avatar6:
      return {
        title: item?.headline,
        description: item?.previewText,
        featuredImage: {
          alt: item.previewImageComponent?.fields?.imageAsset?.fields?.title,
          src: item.previewImageComponent?.fields?.imageAsset?.fields?.file.url,
          srcset: srcSetFromWidths(
            item.previewImageComponent?.fields?.imageAsset?.fields?.file.url,
            SRCSET_WIDTHS,
            resizeParams
          ),
          theme: item.previewImageComponent?.fields?.theme
        },
        linkUrl: correctedUrl(item.relativeUrl)
      };
    default:
      return {};
  }
};

export const getThemedItemIds = (themeObject) => {
  if (themeObject) {
    return themeObject.map((themedItem) => {
      return themedItem.sys?.id;
    });
  }
  return [];
};
export const filterPaginatedItemPreviewListItems = ({
  itemsData,
  featuredItems,
  excludedItems
}) => {
  const featuredItemIds = featuredItems.map((featuredItem) => {
    return featuredItem.sys?.id;
  });
  const excludedItemIds = excludedItems.map((excludedItem) => {
    return excludedItem.sys?.id;
  });
  // remove excluded items and featured items from array
  let filteredItems = itemsData.filter(
    (item) => !excludedItemIds.includes(item.sys.id) && !featuredItemIds.includes(item.sys.id)
  );
  // sort featured items to the front of the items for display
  filteredItems = featuredItems.concat(filteredItems);
  // remove duplicated items
  filteredItems = filteredItems.filter(
    (item, index, self) => self.findIndex((e) => e.sys.id === item.sys.id) === index
  );

  return filteredItems;
};
export const getPaginatedItemPreviewListItems = ({
  itemsData,
  itemsFormFactor,
  themedItemIds,
  featuredItems,
  excludedItems,
  sourceTagItemId,
  itemsPerPage,
  isPopulatedByPathOrTag,
  screenWidth
}) => {
  const filteredItems = filterPaginatedItemPreviewListItems({
    itemsData,
    featuredItems,
    excludedItems
  });
  const numOfPages = Math.ceil(filteredItems.length / itemsPerPage);
  const paginatedItems = Array(numOfPages)
    .fill()
    .map((_, i) => {
      return filteredItems.slice(i * itemsPerPage, (i + 1) * itemsPerPage);
    });

  const itemsAsProps = paginatedItems.map((pageItems) => {
    return pageItems.map((item) => {
      const isThemed = themedItemIds.includes(item.sys?.id);

      return createItemProps(
        // Discriminate between featured items and Tag/Path children
        isPopulatedByPathOrTag && !item.fields
          ? {
              ...item,
              isThemed,
              sourceTagItemId
            }
          : {
              ...item.fields,
              id: item.sys?.id,
              contentType: item.sys?.contentType?.sys?.id,
              isThemed,
              sourceTagItemId
            },
        itemsFormFactor,
        screenWidth
      );
    });
  });

  return itemsAsProps;
};
