<script>
  export let containerClasses = "";
  export let spinnerClasses = "";

  export let screenreaderText = "Loading...";
</script>

<div class="flex items-center {containerClasses}">
  <div
    class="spinner-border animate-spin inline-block my-10 border-4 rounded-full text-gray-700 {spinnerClasses}"
    role="status"
  >
    <span class="sr-only">{screenreaderText}</span>
  </div>
</div>

<style>
  .spinner-border {
    vertical-align: -0.125em;
    border: 0.25em solid;
    border-right-color: transparent;
  }
</style>
