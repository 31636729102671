<script context="module">
  import BlockQuote from "$lib/contentTypes/BlockQuote.svelte";
  import Embed from "$lib/contentTypes/Embed.svelte";
  import ImageComponent from "$lib/contentTypes/ImageComponent.svelte";
  import LinkComponent from "$lib/contentTypes/LinkComponent.svelte";
  import Video from "$lib/contentTypes/Video.svelte";

  // TODO: Double-check child components are valid before launch
  export const DEFAULT_COMPONENT_MAP = {
    blockQuote: BlockQuote,
    embed: Embed,
    imageComponent: ImageComponent,
    linkComponent: LinkComponent,
    video: Video
  };
</script>

<script>
  import RichText from "$lib/components/RichText.svelte";

  /** @type { string } */
  let className = "";
  export { className as class };
  export let data;
  export let componentMap = DEFAULT_COMPONENT_MAP;
</script>

<RichText node={data.fields.richTextContent} {componentMap} class={className} />
