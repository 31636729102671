<script>
  import { getContext } from "svelte";
  import WordCloud from "$lib/components/WordCloud.svelte";
  import { srcSetFromWidths, SRCSET_WIDTHS, sizesForBreakpoints } from "$lib/utilities";

  export let data;

  /** @type { string } */
  let className = "";
  export { className as class };

  export let resizeParams = {};

  // Boolean used to ignore the Contentful Image API image resizing logic
  // Introduced to remove chromatic image flakiness caused by small differences in images
  let ignoreContentfulImgResize = getContext("ignoreContentfulImgResize");

  const contents = data.fields.richTextContent.content[0].content;

  let obj = {};
  let contentList = contents.map((item) => {
    if (item.nodeType === "text") {
      obj = { content: item.value.trim(), type: "text" };
    } else if (item.nodeType === "embedded-entry-inline") {
      obj = {
        content: item.data.target.fields.imageAsset.fields.file.url,
        srcset: !ignoreContentfulImgResize
          ? srcSetFromWidths(
              item.data.target.fields.imageAsset.fields.file.url,
              SRCSET_WIDTHS,
              resizeParams
            )
          : null,
        sizes: sizesForBreakpoints({ sm: "80px", lg: "140px" }),
        theme: item.data.target.fields?.theme,
        type: "image"
      };
    }

    return obj;
  });
  let dataProps = {
    contentList,
    linkLabel: data.fields.callToAction?.fields?.text,
    linkUrl: data.fields.callToAction?.fields?.destination?.fields?.url,
    animateOnView: data.fields.animateOnView
  };
</script>

<WordCloud class={className} {...dataProps} />
