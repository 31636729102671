<script>
  import { getContext } from "svelte";
  import { VideoPlaylist, GridContainer } from "@ObamaFoundation/of-design-system";

  /** @type { string } */
  let className = "";
  export { className as class };
  export let videos;

  const isContainerized = !!getContext("containerized");
</script>

<svelte:head>
  <script src="https://www.youtube.com/iframe_api"></script>
</svelte:head>

{#if isContainerized}
  <VideoPlaylist {videos} class={className} />
{:else}
  <GridContainer containerClass={className}>
    <VideoPlaylist {videos} class={className} />
  </GridContainer>
{/if}
