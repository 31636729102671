const LOW_TIER_CATEGORIES = [
  "$5,001 to $9,999",
  "$2,001 to $5,000",
  "$1,001 to $2,000",
  "$501 to $1,000",
  "$200 to $500"
];

function getAnnonatedContributorName(row) {
  const name = row["Attribution Nameline"];
  const annotation =
    row.flag.toUpperCase() === "NEW"
      ? "*"
      : row.flag.toUpperCase().startsWith("BEQUEST") // this flag is sometimes suffixed by a question mark
        ? "+"
        : "";
  return name + annotation;
}

export function sortContributorsByCategory(contributors) {
  return contributors.reduce((prev, row) => {
    const next = { ...prev };
    if (!row.Category) {
      return next;
    }
    const item = {
      name: getAnnonatedContributorName(row),
      category: row.Category
    };
    if (prev[row.Category]?.length > 0) {
      next[row.Category] = [...next[row.Category], item];
    } else {
      next[row.Category] = [item];
    }
    return next;
  }, {});
}

export function getLowTierContributors(contributorsByCategory) {
  return LOW_TIER_CATEGORIES.reduce(
    (items, cat) => items.concat(contributorsByCategory[cat] ?? []),
    []
  ).map((contributor) => {
    return { name: `${contributor.name} (${contributor.category})` };
  });
}

export function getHighTierContributors(contributorsByCategory) {
  return Object.keys(contributorsByCategory)
    .filter((cat) => !LOW_TIER_CATEGORIES.includes(cat))
    .sort((a, b) => {
      function getHigherRange(cat) {
        const digitsRegex = /\d+/g;
        const thousandSeparatorRegex = /,/g;
        const [lowerRange, higherRange] = cat
          .replace(thousandSeparatorRegex, "")
          .matchAll(digitsRegex);
        // exception: "$1,000,000+"
        if (!higherRange) {
          return lowerRange[0];
        }
        // most categories: "$500,001 to $999,999"
        return higherRange[0];
      }

      return getHigherRange(a) - getHigherRange(b) > 0 ? -1 : 1;
    })
    .map((category) => ({
      category,
      contributors: contributorsByCategory[category]
    }));
}
