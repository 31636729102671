<script>
  export let contributors = [];
  export let ulClasses;
  export let liClasses;
</script>

<ul class={ulClasses}>
  {#each contributors as contributor}
    <li class={liClasses}>{contributor.name}</li>
  {/each}
</ul>
